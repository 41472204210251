import axios from "axios";
import {API_URLS} from '../constants';

const postBenevit = (benevit) => {
    const host = localStorage.getItem('host') + '/api/v1/';
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data',
            }
        };
        let formData = new FormData();
        for (const [key, value] of Object.entries(benevit)) {
            switch (typeof benevit[`${key}`]) {
                case 'object':
                    if (benevit[`${key}`] instanceof Array) {
                        benevit[`${key}`].map((val) => formData.append(`${key}[]`, val));
                    }
                    if (benevit[`${key}`] instanceof File) {
                        formData.append(`${key}`, value, value.name);
                    }
                    break;
                default:
                    formData.append(`${key}`, value);
            }
        }
        return new Promise((resolve, reject) => (
            axios
                .post(host + API_URLS.BENEVITS, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ))
    } catch (error) {
        console.log(error);
    }
};

const updateBenevit = async (benevit) => {
    const host = localStorage.getItem('host') + '/api/v1/';
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data',
            }
        };
        let formData = new FormData();
        for (const [key, value] of Object.entries(benevit)) {
            switch (typeof benevit[`${key}`]) {
                case 'object':
                    if (benevit[`${key}`] instanceof Array) {
                        benevit[`${key}`].map((val) => formData.append(`${key}[]`, val));
                    }
                    if (benevit[`${key}`] instanceof File) {
                        formData.append(`${key}`, value, value.name);
                    }
                    break;
                default:
                    formData.append(`${key}`, value);
            }
        }

        const result = await  axios.put(`${host + API_URLS.BENEVITS}/${benevit?.id}`, formData, headers);
        return result.data;
    } catch (e) {
        console.log(e)
    }


};

const getBenevits = async () => {
    const x = Math.floor(Math.random() * 500) + 1;
    const host = localStorage.getItem('host') + '/api/v1/';
    const headers = {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
            "X-Case": (Math.round((new Date()).getTime() / 1000)) * x,
            "X-Trace": x
        }
    };

    return new Promise((resolve, reject) =>
        axios
            .get(host + API_URLS.BENEVITS, headers)
            .then((response) => response.data)
            .then(resolve)
            .catch((error) => error)
            .then(reject)
    )
};

const uploadImage = (benevit) => {
    const host = localStorage.getItem('host') + '/api/v3/';
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                'Content-Type': 'multipart/form-data',
            }
        };
        let formData = new FormData();
        for (const [key, value] of Object.entries(benevit)) {
            switch (typeof benevit[`${key}`]) {
                case 'object':
                    if (benevit[`${key}`] instanceof Array) {
                        benevit[`${key}`].map((val) => formData.append(`${key}[]`, val));
                    }
                    if (benevit[`${key}`] instanceof File) {
                        formData.append(`${key}`, value, value.name);
                    }
                    break;
                default:
                    formData.append(`${key}`, value);
            }
        }
        return new Promise((resolve, reject) => (
            axios
                .post(host + API_URLS.UPLOAD_IMAGE_BENEVITS, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ))
    } catch (error) {
        console.log(error);
    }
};


export default {
    postBenevit,
    getBenevits,
    updateBenevit
}