import React from "react";
import {withStyles}from "@material-ui/core/styles";
import {
    Paper,
    TableCell,
    TableRow,
    TableBody
} from "@material-ui/core";
const styles = {
    papser: {
        width: "100%",
        overflowX: "auto"
    }
}
class PollRow extends React.Component {
    render() {
        const {poll, handleClick}=this.props;
        return(
            <TableRow hover onClick={() => handleClick(poll)}>
                <TableCell>{poll.id}</TableCell>
                <TableCell>{poll.name}</TableCell>
                <TableCell>{poll.questions.length}</TableCell>
                <TableCell>{0}</TableCell>
            </TableRow>
        )
    }
}

export default withStyles(styles)(PollRow);