import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Chip,
  Paper,
  Divider,
  Typography,
  withStyles,
  CircularProgress,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
  Publish as PublishIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import wsAlly from "../../api/wsAlly";
import wsBenevit from "../../api/wsBenevit";
import wsWallet from "../../api/wsWallet";
import wsCategory from "../../api/wsCategory";
import wsTerritory from "../../api/wsTerritory";
import _ from "lodash";
import classNames from "classnames";
import MUIRichTextEditor from "mui-rte";
import { useFormik } from "formik";
import * as yup from "yup";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import styles from "./styles";
import moment from "moment";
import { useBenevit } from "./BenevitWrapper";
import { useHistory, useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let hostname = window.location.hostname;
const API_URL =
  hostname.includes("localhost") || hostname.includes("socioqa")
    ? "https://socioqa.tecnologiaseficientesdevilla.com/api/v3/admin/upload-image"
    : "https://api.socioinfonavit.com/api/v3/admin/upload-image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  title: yup.string().required(),
  expiration_date: yup.date().required(),
  territories: yup.array().required(),
  categories: yup.array().required(),
  ally_id: yup.number().required(),
  wallet_id: yup.number().required(),
});

const convertStringToRichTextEditorState = (textHtml) => {
  const blocksFromHTML = convertFromHTML(textHtml);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return JSON.stringify(convertToRaw(state));
};

const Benevits = ({ classes }) => {
  const { benevit, setBenevit, showSnackBar } = useBenevit();
  const fixedHeightPaper = classNames(classes.paper, classes.fixedHeight);
  const history = useHistory();
  const params = useParams();
  const textEditorRef = useRef();
  const [allies, setAllies] = useState({
    fetch: false,
    data: [],
  });
  const [wallets, setWallets] = useState({
    fetch: false,
    data: [],
  });
  const [territories, setTerritories] = useState({
    fetch: false,
    data: [],
  });
  const [services, setServices] = useState({
    fetch: false,
    data: [],
  });
  const {
    values,
    dirty,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    handleReset,
    errors,
    setValues,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      //@formatter:off
      name: benevit?.name ?? null,
      title: benevit?.title ?? null,
      expiration_date: benevit?.expiration_date
        ? moment(benevit?.expiration_date).format()
        : null,
      discount: benevit?.discount ?? null,
      discount_code: benevit?.discount_code ?? null,
      url_web: benevit?.url_web ?? null,
      url_maps: benevit?.url_maps ?? null,
      territories: benevit?.territories.map((territory) => territory.id) ?? [],
      categories: benevit?.categories.map((category) => category.id) ?? [],
      primary_color: benevit?.primary_color ?? null,
      ally_id: benevit?.ally.id ?? null,
      wallet_id: benevit?.wallet.id ?? null,
      instructions: convertStringToRichTextEditorState(
        benevit?.instructions ?? ""
      ),
      vector: benevit?.vector_full_path ?? null,
      instructionsToHtml: benevit?.instructions ?? null,
      is_available_in_all_territories:
        benevit?.is_available_in_all_territories ?? false,
      is_available_in_ecommerce: benevit?.is_available_in_ecommerce ?? false,
      is_available_in_physical_store:
        benevit?.is_available_in_physical_store ?? false,
      active: benevit?.active ?? false,
      //formatter:on
    },
    onSubmit: (values, { resetForm, setSubmitting }) => {
      console.log("valores", values);
      const { instructionsToHtml, expiration_date, vector } = values;
      const copyValues = { ...values };
      Object.assign(copyValues, {
        instructions: instructionsToHtml,
        expiration_date: moment(expiration_date).format("YYYY-MM-DD"),
      });
      console.log(copyValues);
      switch (params?.action) {
        case "nuevo":
          wsBenevit
            .postBenevit(copyValues)
            .then((response) => {
              // resetForm();
              showSnackBar("Benevit creado exitosamente.");
              history.push("/u/benevits");
              console.log("hola");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setSubmitting(false);
            });
          break;
        case "editar":
          Object.assign(copyValues, {
            id: benevit.id,
          });
          if (vector?.name === undefined)
            Object.assign(copyValues, {
              vector: null,
            });
          wsBenevit
            .updateBenevit(copyValues)
            .then((response) => {
              resetForm({
                values: {
                  //@formatter:off
                  name: response?.name ?? null,
                  title: response?.title ?? null,
                  expiration_date: response?.expiration_date
                    ? moment(response?.expiration_date).format()
                    : null,
                  discount: response?.discount ?? null,
                  discount_code: benevit?.discount ?? null,
                  url_web: benevit?.url_web ?? null,
                  url_maps: benevit?.url_maps ?? null,
                  territories:
                    response?.territories.map((territory) => territory.id) ??
                    [],
                  categories:
                    response?.categories.map((category) => category.id) ?? [],
                  primary_color: response?.primary_color ?? null,
                  ally_id: response?.ally.id ?? null,
                  wallet_id: response?.wallet.id ?? null,
                  instructions: convertStringToRichTextEditorState(
                    response?.instructions ?? ""
                  ),
                  vector: response?.vector_full_path ?? null,
                  instructionsToHtml: response?.instructions ?? null,
                  //formatter:on
                },
              });
              showSnackBar("Benevit actualizado exitosamente.");
              history.push("/u/benevits");
            })
            .catch((error) => {})
            .finally(() => {
              setSubmitting(false);
            });
          break;
        default:
      }
    },
    validateOnChange: false,
    validationSchema: validationSchema,
  });
  useEffect(() => {
    if (benevit === null && params?.action === "editar")
      history.push("/u/benevits");
  }, [benevit]);
  useEffect(() => {
    const fetchAllies = async () => {
      setAllies({ ...allies, fetch: true });
      await wsAlly.getAllies().then((response) => {
        const filter = response.sort((a, b) => (a.name > b.name ? 1 : -1));
        setAllies({ ...allies, data: filter, fetch: false });
      });
    };
    const fetchWallets = async () => {
      setWallets({ ...wallets, fetch: true });
      await wsWallet.getWallets().then((response) => {
        const filter = response.sort((a, b) => (a.name > b.name ? 1 : -1));
        setWallets({ ...wallets, data: filter, fetch: false });
      });
    };
    const fetchTerritories = async () => {
      setTerritories({ ...territories, fetch: true });
      await wsTerritory.getTerritories().then((response) => {
        const filter = response.sort((a, b) => (a.name > b.name ? 1 : -1));
        setTerritories({ ...territories, data: filter, fetch: false });
      });
    };
    const fetchServices = async () => {
      setServices({ ...services, fetch: true });
      await wsCategory.getCategories().then((response) => {
        const filter = response.sort((a, b) => (a.name > b.name ? 1 : -1));
        setServices({ ...services, data: filter, fetch: false });
      });
    };
    fetchAllies();
    fetchWallets();
    fetchTerritories();
    fetchServices();
    return () => {
      if (params?.action === "editar") setBenevit(null);
    };
  }, []);
  useEffect(() => {
    if (
      allies.data.length > 0 &&
      wallets.data.length > 0 &&
      territories.data.length > 0 &&
      services.data.length > 0
    ) {
      resetForm();
    }
  }, [allies, wallets, territories, services]);
  useEffect(() => {
    setFieldValue(
      "is_available_in_all_territories",
      values.territories.some(
        (territory) => territory === 1 || territory === 34
      )
    );
  }, [values]);

  let options = {
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "link") {
        const data = entity.getData();
        return {
          element: "a",
          attributes: {
            href: data.url,
            target: "_blank",
          },
        };
      } else if (entityType === "image") {
        const data = entity.getData();
        return {
          element: "img",
          attributes: {
            src: data.url,
          },
        };
      }
    },
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}`, {
              method: "post",
              body: body,
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("res:", res);
                resolve({
                  default: `${res.url}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const inputHandler = (event, editor, state) => {
    setFieldValue("instructionsToHtml", editor.getData());
  };

  return (
    <React.Fragment>
      <div className={classes.appBarSpacer} />
      <Paper className={fixedHeightPaper}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          classes={{
            root: classes.container,
          }}
        >
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 20,
              }}
            >
              <Typography component={"div"}>
                <Grid
                  container
                  component={"label"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Grid item>No activo</Grid>
                  <Grid item>
                    <Switch
                      size={"small"}
                      name={"active"}
                      checked={values.active}
                    />
                  </Grid>
                  <Grid item> Activo</Grid>
                </Grid>
              </Typography>
            </div>
          </>
          <Grid
            md={12}
            item
            classes={{
              root: classes.sectionHeader,
            }}
          >
            <Grid container direction="row" spacing={5}>
              <Grid item sm={12} md={12}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item sm={6} md={6}>
                    <Typography variant={"h6"}>
                      {params?.action === "editar" ? "Actualizar" : "Nuevo"}{" "}
                      Benevit
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    classes={{
                      root: classes.actionsContainer,
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="default"
                          size="small"
                          className={classes.button}
                          startIcon={<ArrowBackIcon />}
                          onClick={() => history.push("/u/benevits")}
                        >
                          Volver
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="default"
                          size="small"
                          className={classes.button}
                          startIcon={<SettingsBackupRestoreIcon />}
                          onClick={handleReset}
                        >
                          Reiniciar
                        </Button>
                      </Grid>
                      <Grid item>
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<SaveIcon />}
                            onClick={handleSubmit}
                            disabled={
                              allies.fetch ||
                              wallets.fetch ||
                              territories.fetch ||
                              services.fetch
                                ? true
                                : !dirty
                            }
                          >
                            {params?.action === "editar"
                              ? "Actualizar"
                              : "Guardar"}
                          </Button>
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/**/}
              <Grid item sm={12} md={12}>
                <Grid
                  container
                  direction="row"
                  align={"center"}
                  justify={"space-between"}
                  spacing={5}
                >
                  <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        margin={"dense"}
                        size="small"
                        error={errors.name && touched.name}
                        variant={"outlined"}
                        fullWidth
                        type="text"
                        label="Nombre del benevit"
                        onChange={handleChange}
                        value={values.name || ""}
                        name="name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <FormControl
                      margin={"dense"}
                      fullWidth
                      variant="outlined"
                      id={"ally_id"}
                      disabled={allies.fetch}
                    >
                      <InputLabel
                        id={"aliado"}
                        variant="outlined"
                        classes={{
                          outlined: classes.outlined,
                        }}
                      >
                        Aliado
                      </InputLabel>
                      <Select
                        error={errors.ally_id && touched.ally_id}
                        name={"ally_id"}
                        classes={{
                          root: classes.selectRoot,
                        }}
                        value={
                          //formatter:off
                          values.ally_id ?? ""
                          //formatter:on
                        }
                        label="Aliado"
                        onChange={handleChange}
                      >
                        {allies.data.map((a) => {
                          return (
                            <MenuItem key={a.id} value={a.id}>
                              {a.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {allies.fetch && <LinearProgress />}
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <FormControl
                      disabled={wallets.fetch}
                      margin={"dense"}
                      fullWidth
                      variant={"outlined"}
                      id={"wallet_id"}
                    >
                      <InputLabel
                        id={"wallet_id"}
                        variant={"outlined"}
                        classes={{
                          outlined: classes.outlined,
                        }}
                      >
                        Cartera
                      </InputLabel>
                      <Select
                        error={errors.wallet_id && touched.wallet_id}
                        classes={{
                          root: classes.selectRoot,
                        }}
                        value={values.wallet_id}
                        label="Cartera"
                        id={"wallet_id"}
                        onChange={handleChange}
                        name="wallet_id"
                      >
                        {wallets.data.map((a) => {
                          return (
                            <MenuItem key={a.id} value={a.id}>
                              {a.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {wallets.fetch && <LinearProgress />}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            classes={{
              root: classes.sectionContent,
            }}
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={5}
            >
              <Grid item md={8} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    size="small"
                    error={errors.title && touched.title}
                    variant={"outlined"}
                    fullWidth
                    type="text"
                    label="Título del benevit"
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControl fullWidth>
                  <KeyboardDatePicker
                    error={errors.expiration_date && touched.expiration_date}
                    autoOk
                    invalidDateMessage={""}
                    inputVariant="outlined"
                    label="Vigencia"
                    margin={"dense"}
                    format="yyyy-MM-dd"
                    name={"expiration_date"}
                    value={values.expiration_date}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(dt, value) => {
                      const date = moment(value);
                      const today = moment();
                      if (date.isValid()) {
                        if (
                          date.format("YYYY-MM-DD") < today.format("YYYY-MM-DD")
                        )
                          setFieldValue("active", false);
                        else setFieldValue("active", true);
                      }
                      setFieldValue("expiration_date", date.format());
                      // this.setState({expiration_date: e})
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={12} sm={12}>
                <Divider />
              </Grid>

              <Grid item sm={12} md={2}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    size="small"
                    error={errors.discount && touched.discount}
                    variant={"outlined"}
                    fullWidth
                    type="text"
                    label="Etiqueta de descuento (opcional)"
                    onChange={handleChange}
                    value={values.discount || ""}
                    name="discount"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={2}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    size="small"
                    error={errors.discount_code && touched.discount_code}
                    variant={"outlined"}
                    fullWidth
                    type="text"
                    label="Código de descuento (opcional)"
                    onChange={handleChange}
                    value={values.discount_code || ""}
                    name="discount_code"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    size="small"
                    error={errors.url_web && touched.url_web}
                    variant={"outlined"}
                    fullWidth
                    type="text"
                    label="Website URL (opcional)"
                    onChange={handleChange}
                    value={values.url_web || ""}
                    name="url_web"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    size="small"
                    error={errors.url_maps && touched.url_maps}
                    variant={"outlined"}
                    fullWidth
                    type="text"
                    label="Mapa URL (opcional)"
                    onChange={handleChange}
                    value={values.url_maps || ""}
                    name="url_maps"
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  ref={textEditorRef}
                  data={values.instructionsToHtml}
                  // data={values.instructionsToHtml}
                  label=""
                  inlineToolbar={true}
                  onSave={(state) => {
                    setFieldValue("instructions", state);
                  }}
                  onReady={(editor) => {}}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  onChange={inputHandler}
                />
              </Grid>
              {/* <Grid item md={12}>
                <MUIRichTextEditor
                  controls={[
                    "title",
                    "bold",
                    "italic",
                    "strikethrough",
                    "underline",
                    "undo",
                    "redo",
                    "link",
                    "media",
                    "numberList",
                    "bulletList",
                  ]}
                  ref={textEditorRef}
                  defaultValue={values.instructions}
                  label="Metodo de redencion..."
                  inlineToolbar={true}
                  onSave={(state) => {
                    setFieldValue("instructions", state);
                  }}
                  onChange={(state) => {
                    setFieldValue(
                      "instructionsToHtml",
                      stateToHTML(state.getCurrentContent(), options)
                    );
                  }}
                />
              </Grid> */}
              <Grid item md={4} sm={12}>
                <FormControl
                  disabled={territories.fetch}
                  margin={"dense"}
                  fullWidth
                  classes={{
                    root:
                      values.territories.length > 0
                        ? classes.multiHasContent
                        : "",
                  }}
                >
                  <InputLabel
                    id="territorio"
                    variant={"outlined"}
                    classes={{
                      outlined: classes.outlined,
                    }}
                  >
                    Disponible en
                  </InputLabel>
                  <Select
                    name={"territories"}
                    error={errors.territories && touched.territories}
                    variant={"outlined"}
                    labelid="territorio"
                    id="territorio-select"
                    multiple
                    value={
                      territories.data.length > 0 ? values.territories : ""
                    }
                    onChange={(e) =>
                      setFieldValue("territories", e.target.value)
                    }
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((id) => {
                          const findValue = _.find(territories.data, { id });
                          return (
                            <Chip
                              key={findValue.id}
                              label={findValue.name}
                              className={classes.chip}
                            />
                          );
                        })}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {territories.data.map((territory) => (
                      <MenuItem key={territory.id} value={territory.id}>
                        {territory.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {territories.fetch && <LinearProgress />}
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControl
                  disabled={services.fetch}
                  margin={"dense"}
                  fullWidth
                  classes={{
                    root:
                      values.categories.length > 0
                        ? classes.multiHasContent
                        : "",
                  }}
                >
                  <InputLabel
                    htmlFor="categorias-select"
                    variant={"outlined"}
                    classes={{
                      outlined: classes.outlined,
                    }}
                  >
                    Categorías
                  </InputLabel>
                  <Select
                    name={"categories"}
                    error={errors.categories && touched.categories}
                    variant={"outlined"}
                    id="categorias-select"
                    multiple
                    value={services.data.length > 0 ? values.categories : ""}
                    onChange={(e) =>
                      setFieldValue("categories", e.target.value)
                    }
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((id) => {
                          const findValue = _.find(services.data, { id });
                          return (
                            <Chip
                              key={findValue.id}
                              label={findValue.name}
                              className={classes.chip}
                            />
                          );
                        })}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {services.data.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {services.fetch && <LinearProgress />}
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12}>
                <FormControl fullWidth>
                  <TextField
                    margin={"dense"}
                    onChange={handleChange}
                    name={"primary_color"}
                    value={values.primary_color}
                    label="Color"
                    variant="outlined"
                    type={"text"}
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                item
                md={4}
              >
                <Grid item md={12}>
                  {values.vector && (
                    <div className={classes.imagePreview}>
                      <img
                        //@formatter:off
                        src={values.vector?.url ?? values.vector}
                        //@formatter:on
                        alt=""
                      />
                    </div>
                  )}
                </Grid>
                <Grid container item md={12}>
                  <Button
                    fullWidth
                    component={"label"}
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.button}
                    startIcon={<PublishIcon />}
                  >
                    Seleccionar Imagen
                    <input
                      name={"vector"}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const { files } = e.target;
                        const createUrl = URL.createObjectURL(files[0]);
                        Object.assign(files[0], { url: createUrl });
                        setFieldValue("vector", e.target.files[0]);
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid container item md={8} direciton={"row"}>
                <Grid item md={6}>
                  <FormControlLabel
                    label={"Disponible en tiendas físicas"}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name={"is_available_in_physical_store"}
                        checked={values.is_available_in_physical_store}
                      />
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    label={"Disponible a través de E-commerce"}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name={"is_available_in_ecommerce"}
                        checked={values.is_available_in_ecommerce}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div className={classes.appBarSpacer} />
    </React.Fragment>
  );
};

export default withStyles(styles.form)(Benevits);
