import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        width: '100%',
        paddingTop: '1em',
        paddingBottom: '1em',
    },
    selected: {
        backgroundColor: 'blue'
    }
});

const MenuItem = ({ selected, label, classes, route }) => {
    return (

        <Link to={{ pathname: route }} style={{ textDecoration: 'none' }}>
            <ListItem
                button
                selected={selected}
                className={classNames(classes.root, {
                    [classes.selected]: selected
                })}
            >
            <span style={{ color: 'black' }}>
                {label}
            </span>
            </ListItem>
        </Link>
    )

}

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
}

export default withStyles(styles)(MenuItem);