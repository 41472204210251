import React from "react";
import {withStyles}from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableBody
} from "@material-ui/core";
import PollQuestion from "./PollQuestion";
const styles = {
    papser: {
        width: "100%",
        overflowX: "auto"
    }
}
class Poll extends React.Component {
    render() {
        const {classes, poll}=this.props;
        if (!poll) { return <span />}
        else {
            return(
                <div>
                    <div>
                    <center>
                    <h1>{poll.name}</h1>
                    </center>
                    </div>
                    <div>
                        <h3>Preguntas</h3>
                        {poll.questions.sort((a, b) => (a.question_index > b.question_index) ? 1 : -1).map((q) => {
                            return <PollQuestion key={q.id} question={q} />
                        })}
                    </div>
                </div>
            );
        }
    }
}

export default withStyles(styles)(Poll);