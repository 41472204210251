import React from "react";
import { Grid, Button, TextField, Select, MenuItem } from "@material-ui/core";
import { Color } from "../../utils/Constants";
import logo from "../../assets/images/logo.png";
import logoWhite from "../../assets/images/logoWhite.png";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import history from "../../history";

import wsUser from "../../api/wsUser";

import { BASE_DEV, BASE_QA, BASE_PROD } from "../../constants/index";

const theme = createMuiTheme({
  palette: {
    primary: Color.PRIMARY_UI,
  },
  typography: {
    useNextVariants: true,
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loggingIn: false,
      errorMessage: null,
      host: "https://api.example.com",
    };
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onHostChange = this.onHostChange.bind(this);
  }
  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  onHostChange(e) {
    this.setState({ host: e.target.value });
  }
  handleLogin = async () => {
    const { email, password, host } = this.state;
    const response = await wsUser.login(host, email, password);
    if (!response.errors) {
      if (response.user.role === "admin") {
        localStorage.setItem("host", host);
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("token", response.token);
        history.push("/u");
      } else {
        this.setState({
          errorMessage: "Este usuario no es administrador",
        });
      }
    } else {
      if (response.errors.status === 401) {
        this.setState({
          errorMessage: "El correo o contraseña son incorrectos.",
        });
      }
    }
  };
  renderErrorMessage() {
    if (this.state.errorMessage) {
      return <small style={{ color: "red" }}>{this.state.errorMessage}</small>;
    } else {
      return <small style={{ color: "white" }}>-</small>;
    }
  }
  render() {
    const { email, password, host } = this.state;
    return (
      <div className="app">
        <div className="fadeIn animated">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={5}
              style={{ height: "100vh", backgroundColor: "white" }}
            >
              <img
                src={logo}
                className="login_logo"
                alt="socio infonavit logo"
              />
              <div className="custom_form">
                <MuiThemeProvider theme={theme}>
                  <Select value={host} onChange={this.onHostChange} fullWidth>
                    <MenuItem value="http://localhost:3000">Local</MenuItem>
                    <MenuItem value="https://socioqa.tecnologiaseficientesdevilla.com">
                      Staging
                    </MenuItem>
                    <MenuItem value="https://api.socioinfonavit.com">
                      Producción
                    </MenuItem>
                  </Select>
                  <TextField
                    autoFocus
                    margin="dense"
                    fullWidth
                    type="email"
                    label="Correo electrónico"
                    value={email}
                    onChange={this.onEmailChange}
                  />
                  <TextField
                    style={{ marginBottom: 10 }}
                    autoFocus
                    fullWidth
                    margin="dense"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.handleLogin();
                      }
                    }}
                    type="password"
                    label="Contaseña"
                    value={password}
                    onChange={this.onPasswordChange}
                  />
                  {this.renderErrorMessage()}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%", height: "50px", marginTop: 10 }}
                    onClick={this.handleLogin}
                  >
                    Iniciar sesión
                  </Button>
                </MuiThemeProvider>
              </div>
            </Grid>
            <Grid item xs={12} md={7} className="side_img">
              <center>
                <img src={logoWhite} alt="socio infonaavit logo" />
              </center>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Login;
