import React, {Component} from 'react';
import {
    withStyles,
    createStyles,
    Paper,
    Grid,
    Typography,
    Button,
    Divider,
    Switch,
    FormControlLabel,
    CircularProgress,
    Snackbar,
    SnackbarContent,
    Icon,
} from "@material-ui/core";
import {
    Publish as PublishIcon
} from '@material-ui/icons';
import classNames from 'classnames';
import {Formik} from 'formik';
import wsTemporality from '../../api/wsTemporality';
import {green} from "@material-ui/core/colors/index";

const styles = createStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(4),
        display: 'flex',
        overflow: 'auto',
    },
    fixedHeight: {
        height: 'auto',
        overflow: 'hidden'
    },
    imagePreview: {
        padding: theme.spacing(1),
        '& img': {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            border: 'solid 1px',
            '&.desktop': {
                width: '100%'
            },
            '&.mobile': {
                width: '70%'
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
}));

class Temporality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            desktop_theme_: null,
            desktop_theme_url: null,
            has_active_theme: false,
            mobile_theme_url: null,
            mobile_theme: null,
            theme_logo_alt_url: null,
            theme_logo_url: null,
            snackbar: {
                open: false,
                message: ''
            }
        };
    }


    componentDidMount() {
        wsTemporality.getTemporality().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                ...response
            }));
        })

    }


    render() {
        const {classes} = this.props;
        const fixedHeightPaper = classNames(classes.paper, classes.fixedHeight);
        return (
            <React.Fragment>
                <div className={classes.appBarSpacer}/>
                <Paper className={fixedHeightPaper}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            has_active_theme: this.state.has_active_theme,
                            desktop_theme: null,
                            mobile_theme: null
                        }}
                        onSubmit={(values, {resetForm, setSubmitting}) => {
                            const {
                                has_active_theme,
                                desktop_theme,
                                mobile_theme
                            } = values;
                            wsTemporality.putTemporality(has_active_theme, desktop_theme, mobile_theme)
                                .then((response) => {
                                    resetForm({has_active_theme: true});
                                    this.setState((pevState) => ({
                                        ...pevState,
                                        ...response,
                                        snackbar: {
                                            open: true,
                                            message: 'Actualización de la temporalidad con éxito.'
                                        }
                                    }));
                                })
                                .catch((error) => {
                                    this.setState((pevState) => ({
                                        ...pevState,
                                        snackbar: {
                                            open: true,
                                            message: 'Error al intentar actualizar la temporalidad, intentalo más tarde.'
                                        }
                                    }));
                                    console.log(error);
                                    resetForm();
                                }).finally(() => {
                                setSubmitting(false);

                            });
                        }}
                    >
                        {({values, handleSubmit, handleChange, isSubmitting, touched, setFieldValue, dirty}) => {
                            return (
                                <Grid
                                    container
                                    directon={'row'}
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        container
                                        direction={'row'}
                                    >
                                        <Grid
                                            item
                                            sm={12}
                                            md={6}
                                            container
                                            justify={'flex-start'}
                                            direction={'row'}
                                            alignItems={'center'}
                                        >
                                            <Typography variant={'h6'}>Temporalidades</Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            sm={12}
                                            md={6}
                                            justify={'flex-end'}
                                            direction={'row'}
                                            alignItems={'center'}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                lg={4}
                                            >
                                                <div className={classes.wrapper}>
                                                    <Button
                                                        disabled={!dirty || isSubmitting}
                                                        variant={'contained'}
                                                        fullWidth
                                                        color="secondary"
                                                        onClick={handleSubmit}
                                                    >
                                                        Actualizar
                                                    </Button>
                                                    {
                                                        isSubmitting && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        md={12}
                                        directon={'row'}
                                        alignItems={'flex-end'}
                                        justify={'center'}
                                        spacing={4}
                                    >
                                        <Grid
                                            item
                                            md={12}
                                            container
                                            alignItems={'center'}
                                            justify={'center'}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={values.has_active_theme}
                                                        name={'a'}
                                                        inputProps={{
                                                            'aria-label': 'checkbox primary'
                                                        }}
                                                        onChange={(event) => {
                                                            const {checked} = event.target;
                                                            setFieldValue('has_active_theme', checked);
                                                        }}
                                                    />
                                                }
                                                label={'Activar'}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            md={6}
                                            alignItems={'center'}
                                            justify={'center'}
                                        >
                                            <Grid item md={6} lg={4}>
                                                {
                                                    this.state.desktop_theme_url && (
                                                        <div className={classes.imagePreview}>
                                                            <img
                                                                className={'desktop'}
                                                                //@formatter:off
                                                            src={this.state.desktop_theme_url ?? ''}
                                                            //@formatter:on
                                                                alt=""
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <Button
                                                    fullWidth
                                                    component={'label'}
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    className={classes.button}
                                                    startIcon={<PublishIcon/>}
                                                >
                                                    Subir Imagen Web
                                                    <input
                                                        type="file"
                                                        name={'desktop_theme'}
                                                        style={{display: "none"}}
                                                        onChange={(event) => {
                                                            const {files} = event.target;
                                                            const createUrl = URL.createObjectURL(files[0]);
                                                            this.setState((...prevState) => ({
                                                                ...prevState,
                                                                desktop_theme_url: createUrl
                                                            }));
                                                            setFieldValue('desktop_theme', files[0]);
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            md={6}
                                            alignItems={'center'}
                                            justify={'center'}
                                        >
                                            <Grid item md={6} lg={4}>
                                                {
                                                    this.state.mobile_theme_url && (
                                                        <div className={classes.imagePreview}>
                                                            <img
                                                                className={'mobile'}
                                                                //@formatter:off
                                                            src={this.state.mobile_theme_url ?? ''}
                                                            //@formatter:on
                                                                alt=""
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <Button
                                                    fullWidth
                                                    component={'label'}
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    className={classes.button}
                                                    startIcon={<PublishIcon/>}
                                                >
                                                    Subir Imagen Movile
                                                    <input
                                                        type="file"
                                                        name={'mobile_theme'}
                                                        style={{display: "none"}}
                                                        onChange={(event) => {
                                                            const {files} = event.target;
                                                            const createUrl = URL.createObjectURL(files[0]);
                                                            this.setState((prevState) => ({
                                                                ...prevState,
                                                                mobile_theme_url: createUrl
                                                            }));
                                                            setFieldValue('mobile_theme', files[0]);
                                                        }}
                                                    />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }}
                    </Formik>
                </Paper>
                <Snackbar
                    onClose={() => this.setState((prevState) => ({
                        ...prevState,
                        snackbar: {
                            open: false,
                            message: ''
                        }
                    }))}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={this.state.snackbar.open}
                    autoHideDuration={3500}
                >
                    <SnackbarContent
                        className={classes.success}
                        message={
                            <span className={classes.message}>
              <Icon className={classes.icon}/>
                                {
                                    this.state.snackbar.message
                                }
            </span>
                        }
                    />
                </Snackbar>
                <div className={classes.appBarSpacer}/>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Temporality);