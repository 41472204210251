import React from 'react';
import Wrapper from "./components/container/Wrapper";
import Login from "./components/container/Login";
import Allies from "./components/Allies/AllyWrapper";
import Categories from "./components/Categories/CategoryWrapper";

import Members from "./components/container/Members";
import Benevits from "./components/Benevits/BenevitWrapper";
import PollsView from "./components/container/PollsView";
import Temporality from './components/container/Temporality';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core";
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './history';
import './styles/index.scss';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }
  componentDidMount() {
      document.querySelector("body").bgColor = '#eef0f8';
    this.findUser();
  }

  findUser() {
    try{
      if (localStorage.getItem('user')){
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState( { user });
        if (history.location.pathname === "/login") {
          history.push("/u")
        }
      }
    } catch{
      localStorage.clear();
      history.push("/login");
    }
  }
  render() {
      const defaultTheme = createMuiTheme();
      Object.assign(defaultTheme, {
          overrides: {
              MUIRichTextEditor: {
                  root: {
                      border: 'solid 1px #c4c4c4',
                      borderRadius: 4,
                      padding: '0 14px 14px 14px',
                      minHeight: 300
                  },
                  placeHolder:{
                      fontSize: '0.9rem'
                  }
              },
              MuiInputBase: {
                  input: {
                      fontSize: '0.9rem'
                  }
              },
              MuiMenuItem:{
                  root: {
                      fontSize: '0.9rem'
                  }
              },
              MuiTable: {
                  root: {
                      tableLayout: 'fixed'
                  }
              },
              MUIDataTableHeadCell: {
                  root: {
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      textAlign: 'center'
                  }
              },
              MUIDataTableToolbar: {
                  icon: {
                      padding: 5
                  }
              }
          },
      });

    return (
      <Router history={history}>
        <Switch>
          <Route path="/u" render={() => {
            return (
                <MuiThemeProvider theme={defaultTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Wrapper user={this.state.user}>
                            <Route key="aliados" path="/u/aliados/:action?" component={Allies}/>
                            <Route key="asdf" exact path="/u/benevits/:action?" component={Benevits}/>
                            <Route key="h" path="/u/encuestas" component={PollsView}/>
                            <Route key="temp" path="/u/temporality" component={Temporality}/>
                            <Route key="categorias" path="/u/categorias/:action?" component={Categories}/>
                        </Wrapper>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            )
          }} />
          <Route path="/login" component={Login} />
          <Redirect from="/" to={this.state.user !== null ? "/u" : "/login"} />
        </Switch>
      </Router>
    );
  }
}

export default App;
