import React, {Component, useContext} from 'react';
import BenevitForm from './Benevits'
import BenevitList from "./BenevitList";
import {
    withStyles,
    Snackbar,
    SnackbarContent
} from "@material-ui/core";
import styles from './styles';

const BenevitContext = React.createContext({
    benevit: null
});

class BenevitWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            benevit: null,
            snackbar: {
                open: false,
                message: null
            }
        };
    }

    setBenevit = (benevit) => this.setState({benevit});

    showSnackBar = (message) => this.setState((prevState) => ({...prevState, snackbar: {open: true, message}}));

    hideSnackBar = () => this.setState((prevState) => ({...prevState, snackbar: {open: false, message: null}}));

    render() {
        const {history, match, classes} = this.props;
        const {state: {benevit, snackbar}, setBenevit, showSnackBar, hideSnackBar} = this;
        return (
            <BenevitContext.Provider value={{
                benevit,
                setBenevit,
                showSnackBar,
                hideSnackBar
            }}>
                {
                    match.params.action === undefined
                        ? (<BenevitList/>)
                        : match.params.action !== undefined &&
                        (match.params.action === 'nuevo' || match.params.action === 'editar')
                        ? (<BenevitForm/>)
                        : history.goBack()
                }
                <Snackbar
                    onClose={() => hideSnackBar()}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snackbar.open}
                    autoHideDuration={3500}
                >
                    <SnackbarContent
                        className={classes.success}
                        message={snackbar.message}
                    />
                </Snackbar>
            </BenevitContext.Provider>
        )

    }
}

export const useBenevit = () => useContext(BenevitContext);

export default withStyles(styles.wrapper)(BenevitWrapper);