import React from "react";
import {withStyles}from "@material-ui/core/styles";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@material-ui/core";
import PollRow from "./PollRow";
const styles = {
    paper: {
        height:"700px",
        width: "100%",
        overflow: "auto"
    },
    row:{
        "&:hover":{
            cursor: "pointer",
            backgroundColor: "#e5e5e5"
        }
    }
}
class PollsTable extends React.Component {
    render() {
        const {classes, polls, pollSelected}=this.props;
        return(
            <Paper className={classes.paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.row}>
                            <TableCell>id</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Preguntas</TableCell>
                            <TableCell>Resultados</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            polls.map( (poll) => {
                                return <PollRow key={poll.id} handleClick={pollSelected} poll={poll}/>
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

export default withStyles(styles)(PollsTable);