import React, {useEffect, useState} from 'react';
import {
    Grid,
    TextField,
    Button,
    Divider,
    Paper,
    Typography,
    CircularProgress,
    withStyles,
    FormHelperText
} from '@material-ui/core';
import {
    Save as SaveIcon,
    SettingsBackupRestore as SettingsBackupRestoreIcon,
    Publish as PublishIcon,
    ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import wsAlly from '../../api/wsAlly';
import classNames from 'classnames';
import moment from 'moment';
import {useFormik} from 'formik';
import styles from './styles';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {DropzoneArea} from 'material-ui-dropzone';
import {useAlly} from './AllyWrapper';
import {useHistory, useParams} from 'react-router-dom';
import * as yup from "yup";

const fileNameFromUrl = (url) => url.split("/").pop();

const validationSchema = yup.object().shape({
    name: yup.mixed('El nombre es requerido').required('El nombre es requerido'),
    start_date: yup.date().required('La fecha de inicio es requerida'),
    renovation_date: yup.date().required('La fecha de renovación es requerida'),
    mini_logo: yup.mixed().required('El logo es requerido'),
});

const AllyForm = ({classes}) => {
    const fixedHeightPaper = classNames(classes.paper, classes.fixedHeight);
    const history = useHistory();
    const params = useParams();
    const {ally, setAlly, showSnackBar} = useAlly();
    const [key, setKey] = useState(0);
    const {
        values,
        dirty,
        handleChange,
        setFieldValue,
        isSubmitting,
        handleSubmit,
        handleReset,
        setValues,
        errors
    } = useFormik({
        initialValues: {
            //@formatter:off
            name: ally?.name ?? null,
            representative_name: ally?.representative_name ?? null,
            telephone_number: ally?.telephone_number ?? null,
            email: ally?.email ?? null,
            start_date: ally?.start_date ? moment(ally?.start_date).format() : moment().format(),
            renovation_date: ally?.renovation_date ? moment(ally?.renovation_date).format() : moment().add(1, 'y').format(),
            documents: ally?.documents ?? [],
            mini_logo: ally?.mini_logo_full_path ?? null,
            description: ally?.description ?? null
            //@formatter:on
        },
        onSubmit: (values, {resetForm, setSubmitting}) => {
            const {renovation_date, start_date, documents, mini_logo} = values;
            const copyValues = {...values};
            //Change format for moment instances, into string date with format ex: '2021-03-01'
            Object.assign(copyValues, {
                start_date: moment(start_date).format('YYYY-MM-DD'),
                renovation_date: moment(renovation_date).format('YYYY-MM-DD')
            });
            switch (params?.action) {
                case 'nuevo':
                    //then create a POST request
                    wsAlly.postAlly(copyValues).then((response) => {
                        resetForm();
                        setKey(1);
                        showSnackBar('Aliado creado exitosamente.');
                        history.push('/u/aliados');
                    }).finally(() => {
                        setSubmitting(false);
                    });
                    break;
                case 'editar':
                    //Then create a PUT request
                    Object.assign(copyValues, {
                        id: ally.id
                    });

                    if (!documents.some((document) => document?.name)) Object.assign(copyValues, {
                        documents: []
                    });

                    if (mini_logo?.name === undefined) Object.assign(copyValues, {
                        mini_logo: null
                    });
                    wsAlly.updateAlly(copyValues).then((response) => {
                        setKey(1);
                        resetForm({
                            values: {
                                //@formatter:off
                                name: response?.name ?? null,
                                representative_name: response?.representative_name ?? null,
                                telephone_number: response?.telephone_number ?? null,
                                email: response?.email ?? null,
                                start_date: moment(response?.start_date).format(),
                                renovation_date: moment(response?.renovation_date).format(),
                                documents: response?.documents ?? [],
                                mini_logo: response?.mini_logo_full_path ?? null,
                                description: response?.description ?? null
                                //@formatter:on
                            }
                        });
                        showSnackBar('Aliado actualizado exitosamente.');
                        history.push('/u/aliados');
                    }).finally(() => {
                        setSubmitting(false);
                    });
                    break;
                default:
            }
        },
        validationSchema: validationSchema
    });
    useEffect(() => {
        if (ally === null && params?.action === 'editar') history.push('/u/aliados')
    }, [ally]);
    useEffect(() => {
        return () => {
            if (params?.action === 'editar') setAlly(null)
        }
    }, []);
    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Paper className={fixedHeightPaper}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    classes={{
                        root: classes.container
                    }}
                    spacing={2}
                >
                    <Grid
                        container
                        item
                        md={12}
                        direction="row"
                        spacing={5}
                    >
                        <Grid
                            item
                            md={6}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                {params?.action === 'editar' ? 'Actualizar' : 'Nuevo'} Aliado
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            container
                            direction={'row'}
                            spacing={2}
                            justify={'flex-end'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                md={3}
                            >
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    color={'default'}
                                    size={'small'}
                                    startIcon={<ArrowBackIcon/>}
                                    margin={'dense'}
                                    onClick={() => history.push('/u/aliados')}
                                >
                                    Volver
                                </Button>
                            </Grid>
                            <Grid
                                item
                                md={3}
                            >
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    color={'default'}
                                    size={'small'}
                                    startIcon={<SettingsBackupRestoreIcon/>}
                                    margin={'dense'}
                                    onClick={handleReset}
                                >
                                    Reiniciar
                                </Button>
                            </Grid>
                            <Grid
                                item
                                md={3}
                            >
                                <div className={classes.wrapper}>
                                    <Button
                                        disabled={params?.action === 'editar' && !dirty}
                                        fullWidth
                                        variant={'contained'}
                                        color={'secondary'}
                                        size={'small'}
                                        startIcon={<SaveIcon/>}
                                        margin={'dense'}
                                        onClick={handleSubmit}
                                    >
                                        {params?.action === 'editar' ? 'Actualizar' : 'Guardar'}
                                    </Button>
                                    {
                                        isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={12}
                    >
                        <Divider/>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            md={4}
                        >
                            <TextField
                                error={errors?.name !== undefined}
                                helperText={errors?.name}
                                fullWidth
                                label={'Nombre'}
                                //@formatter:off
                                value={values.name ?? ''}
                                //@formatter:on
                                margin={'dense'}
                                name={'name'}
                                onChange={handleChange}
                                variant={'outlined'}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                        >
                            <TextField
                                fullWidth
                                label={'Contacto'}
                                //@formatter:off
                                value={values.representative_name ?? ''}
                                //@formatter:on
                                margin={'dense'}
                                name={'representative_name'}
                                onChange={handleChange}
                                variant={'outlined'}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                        >
                            <TextField
                                fullWidth
                                //@formatter:off
                                value={values.telephone_number ?? ''}
                                //@formatter:on
                                label={'Telefono'}
                                onChange={handleChange}
                                name={'telephone_number'}
                                margin={'dense'}
                                variant={'outlined'}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                        >
                            <TextField
                                fullWidth
                                label={'Email'}
                                //@formatter:off
                                value={values.email ?? ''}
                                //@formatter:on
                                onChange={handleChange}
                                name={'email'}
                                margin={'dense'}
                                variant={'outlined'}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                        >
                            <KeyboardDatePicker
                                error={errors?.start_date !== undefined}
                                helperText={errors?.start_date}
                                autoOk
                                invalidDateMessage={''}
                                //@formatter:off
                                value={values.start_date}
                                //@formatter:on
                                fullWidth
                                label={'Fecha de inicio'}
                                margin={'dense'}
                                inputVariant={'outlined'}
                                format={'yyyy-MM-dd'}
                                InputAdornmentProps={{position: "end"}}
                                onChange={(date, value) => {
                                    setFieldValue('start_date', moment(value).format())
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={4}
                        >
                            <KeyboardDatePicker
                                error={errors?.renovation_date !== undefined}
                                helperText={errors?.renovation_date}
                                autoOk
                                invalidDateMessage={''}
                                fullWidth
                                label={'Fecha de renovacion'}
                                margin={'dense'}
                                //@formatter:off
                                value={values.renovation_date}
                                //@formatter:on
                                inputVariant={'outlined'}
                                format={'yyyy-MM-dd'}
                                InputAdornmentProps={{position: "end"}}
                                onChange={(date, value) => {
                                    setFieldValue('renovation_date', moment(value).format())
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                variant={'outlined'}
                                margin={'dense'}
                                label={'Descripción'}
                                multiline
                                fullWidth
                                rows={5}
                                name={'description'}
                                //@formatter:off
                                value={values.description ?? ''}
                                //@formatter:on
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        md={12}
                        direction={'row'}
                        justify={'flex-start'}
                        alignItems={'flex-end'}
                        spacing={4}
                    >
                        <Grid
                            item
                            md={4}
                        >
                            {
                                values.mini_logo && (
                                    <div className={classes.imagePreview}>
                                        <img
                                            //@formatter:off
                                            src={values.mini_logo?.url ?? values?.mini_logo}
                                            //@formatter:on
                                            alt=""
                                        />
                                    </div>
                                )
                            }
                            <Button
                                fullWidth
                                component={'label'}
                                variant={'contained'}
                                color={'primary'}
                                size={'medium'}
                                startIcon={<PublishIcon/>}
                            >
                                Logo del aliado
                                <input
                                    type={'file'}
                                    name={'mini_logo'}
                                    style={{display: "none"}}
                                    onChange={(event) => {
                                        const {files} = event.target;
                                        const createUrl = URL.createObjectURL(files[0]);
                                        Object.assign(files[0], {url: createUrl});
                                        setFieldValue('mini_logo', files[0]);
                                    }}
                                />
                            </Button>
                            {
                                errors?.mini_logo !== undefined && (
                                    <FormHelperText error>
                                        {errors?.mini_logo}
                                    </FormHelperText>
                                )
                            }
                        </Grid>
                        <Grid
                            item
                            md={4}
                            container
                            direction={'column'}
                        >
                            <Grid item>
                                <Typography variant={'h6'}>Convenio y otros documentos</Typography>
                            </Grid>
                            {
                                values.documents.length > 0 && (
                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                    >
                                        {
                                            values.documents.map((document, idx) => (
                                                <Grid item key={idx}>
                                                    {/*@formatter:off*/}
                                                    <a href={document?.url ?? document} target="_blank">{idx+=1}) {document?.name ?? fileNameFromUrl(document)}</a>
                                                    {/*@formatter:on*/}
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                )
                            }
                            <Grid item>
                                <DropzoneArea
                                    key={key}
                                    clearOnUnmount={true}
                                    maxFileSize={9000000}
                                    showAlerts={false}
                                    useChipsForPreview
                                    filesLimit={99}
                                    acceptedFiles={['image/*', 'video/*', 'application/*', '*.csv', '*.xlsx', '*.xls', '*.doc', '*.docx', '*.txt']}
                                    dropzoneText="Arrastra aquí el archivo o haz click para adjuntarlo"
                                    onChange={(files) => {
                                        const filesUrl = files.map((file) => {
                                            const createUrl = URL.createObjectURL(file);
                                            Object.assign(file, {url: createUrl});
                                            return file
                                        });
                                        setFieldValue('documents', filesUrl);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <div className={classes.appBarSpacer}/>
        </React.Fragment>
    );
};

export default withStyles(styles.form)(AllyForm);
