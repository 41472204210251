import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    TextField,
    Button,
    Dialog,
    DialogTitle
} from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone';
const styles = {
    root:{
        padding: 20
    },
    form: {
        padding: 10,
        marginLeft: 10,
        marginRight: 10
    },
    textField: {
        marginBottom: 20
    },
    dialogPaper: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },
    button:{
        marginRight: 10
    }
}
class NewOptionDialog extends React.Component {
    constructor(props){
        super(props)
        this.state={
            optionValue: "",
            nextQuestionIndex: -1,
            image: null
        }
        this.handleChange=this.handleChange.bind(this);
        this.renderDropzone = this.renderDropzone.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
    }
    handleCreate(){
        const option = {
            option_value: this.state.optionValue,
            image: this.state.image, 
            next_question_index: this.state.nextQuestionIndex
        }
        this.props.handleCreateOption(option);
        this.setState({optionValue: "", nextQuestionIndex: -1, image: null})
    }
    handleChange(e){
        this.setState({[e.target.name]: e.target.value})
    }
    handleOptionImageChange(files) {
        this.setState({
            image: files[0]
        })
    }
    renderDropzone(){
        if (this.props.canUploadImage){
            return (
                <div style={{width:300, height: 150, marginBottom:150}}>
                    <DropzoneArea showAlerts={false} key={this.state.dropzone1key} filesLimit={1} onChange={this.handleOptionImageChange.bind(this)} />
                </div>
            )
        } else {
            return <span />
        }
        
    }
    render() {
        const {classes}=this.props;
        return (
            <Dialog onClose={this.props.onClose} open={this.props.open} classes={{ paper: classes.dialogPaper }}>
                <DialogTitle>Nueva opción</DialogTitle>
                <div className={classes.form}>
                    <TextField
                        fullWidth
                        label="Opción"
                        name="optionValue"
                        onChange={this.handleChange}
                        value={this.state.optionValue}
                        className={classes.textField}
                    />
                    <br />
                    <TextField
                        label="Padre de pregunta"
                        name="nextQuestionIndex"
                        onChange={this.handleChange}
                        value={this.state.nextQuestionIndex}
                        className={classes.textField}
                    />
                    <br />
                    {this.renderDropzone()}
                    <div >
                        <Button 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.handleCreate}
                        >
                            Crear
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }

}

export default withStyles(styles)(NewOptionDialog)