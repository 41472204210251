import React, {Component, useContext} from 'react';
import AllieForm from './AllyForm';
import AllyList from './AllyList';
import {Snackbar, SnackbarContent, withStyles} from '@material-ui/core';
import styles from './styles';

const AllyContext = React.createContext({
    ally: null
});

class AllyWrapper extends Component {

    constructor(props) {
        super(props);
        // this.setAlly = (ally) => this.setState({ ally });
        this.state = {
            ally: null,
            snackbar: {
                open: false,
                message: null
            }
            // setAlly: this.setAlly
        };
    }

    setAlly = (ally) => this.setState({ally});

    showSnackBar = (message) => this.setState((prevState) => ({...prevState, snackbar: {open: true, message}}));

    hideSnackBar = () => this.setState((prevState) => ({...prevState, snackbar: {open: false, message: null}}));

    render() {
        const {history, match, classes} = this.props;
        const {state: {ally, snackbar}, setAlly, showSnackBar, hideSnackBar} = this;
        return (
            <AllyContext.Provider value={{
                ally,
                setAlly,
                showSnackBar,
                hideSnackBar
            }}>
                {
                    match.params.action === undefined
                        ? (<AllyList/>)
                        : match.params.action !== undefined &&
                        (match.params.action === 'nuevo' || match.params.action === 'editar')
                        ? (<AllieForm/>)
                        : history.goBack()
                }
                <Snackbar
                    onClose={() => hideSnackBar()}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snackbar.open}
                    autoHideDuration={3500}
                >
                    <SnackbarContent
                        className={classes.success}
                        message={snackbar.message}
                    />
                </Snackbar>
            </AllyContext.Provider>
        )
    }
}

export const useAlly = () => useContext(AllyContext);

export default withStyles(styles.wrapper)(AllyWrapper);