import axios from "axios";
import { API_URLS } from '../constants';

const getCategories = async () => {
    const host = localStorage.getItem('host') + '/api/v1/';
    const x = Math.floor(Math.random() * 500) + 1;
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
                "X-Trace":x,
            }
        }
        const res = await axios.get(host + API_URLS.CATEGORIES, headers);
        return res.data;
    } catch(error) {
        console.log(error);
    }

};

const postCategory = async (category) => {
    try {
        const host = localStorage.getItem('host') + '/api/v1';

    const headers = {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        }
    }
    let formData = new FormData();
    formData.set('name', category.name)
    formData.set('color', category.color)
    if (category. mini_icon) {
        formData.set('icon', category.mini_icon)
    }
    if (category. mini_banner) {
        formData.set('banner', category.mini_banner)
    }
        return new Promise((resolve, reject) => (
            axios
                .post(host + API_URLS.CATEGORIES, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ));
    } catch (error) {
        return {error: error}
    }

};
const updateCategory = async (category) => {
    try {
        const host = localStorage.getItem('host') + '/api/v1';

    const headers = {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        }
    }
    let formData = new FormData();
    formData.set('name', category.name)
    formData.set('color', category.color)
    if (category. mini_icon) {
        formData.set('icon', category.mini_icon)
    }
    if (category. mini_banner) {
        formData.set('banner', category.mini_banner)
    }

        return new Promise((resolve, reject) => (
            axios
                .put(host + API_URLS.CATEGORIES + '/' + category.id, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ));
    } catch (error) {
        return {error: error}
    }

};
export default {
    getCategories,
    postCategory,
    updateCategory
}