import axios from 'axios';
import {API_URLS} from '../constants';

const getTerritories = async () => {
    const host = `${localStorage.getItem('host')}/api/v1/`;
    try {
        const trace = Math.floor(Math.random() * 500) + 1;
        const headers = {
            headers: {
                Authorization: localStorage.getItem('token'),
                'X-Case': (Math.round((new Date).getTime() / 1000)) * trace,
                'X-Trace': trace
            }
        };

        const result = await axios.get(`${host}${API_URLS.TERRITORIES}`, headers);
        return result.data;
    } catch (e) {
        console.log(e);
    }
};

export default {
    getTerritories
};