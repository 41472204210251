import React from "react";
import {withStyles}from "@material-ui/core/styles";
import {
    Paper,
    TextField,
    Select,
    MenuItem,
    Chip,
    IconButton,
    Button
} from "@material-ui/core";
import NewOptionDialog from "./NewOptionDialog";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import wsPoll from "../../api/wsPoll";
const styles = {
    root:{
        marginBottom: "1em",
        position: "relative"
    },
    index:{
        position: "absolute",
        top: 10,
        right: 10
    },
    paper: {
        width: '100%',
        overflowX: 'auto',
        padding: 15
    },
    questionText:{
        fontSize: "1.3em",
        color: '#4f4f4f'
    },
    mb:{
        marginBottom:'1em'
    },
    ml:{
        marginLeft:'1.5em'
    },
    mt:{
        marginTop:'3em'
    },
    optionImage: {
        position: "relative",
        width: '300px',
        border: "1px solid #e5e5e5",
        padding: "0.5em",
        display:'flex',
        justify: 'center',
        alignItems:'center'
    },
    optionText: {
        marginLeft: "1em"
    },
    deleteIcon:{
        position:'absolute',
        top: 5,
        right: 5
    },
    button:{
        margin: 5
    }
}
const NewOptionLauncher = (props) => {
    return(
        <Button 
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={props.handleClick}
        >
        Agregar opción
        </Button>
    )
}
class PollQuestion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            questionText: props.question.question_text,
            answerType: props.question.answer_type.key,
        options: props.question.options,
            newOptionDialogOpen: false,
            questionIndex: props.question.question_index
        }
        this.saveQuestion = this.saveQuestion.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleNewOptionClick = this.handleNewOptionClick.bind(this);
        this.handleOptionDialogClose = this.handleOptionDialogClose.bind(this);
        this.createOption = this.createOption.bind(this);
    }
    async saveQuestion(){
        const question = {
            id: this.props.question.id,
            question_text: this.state.questionText,
            answer_type: this.state.answerType,
            poll_id: this.props.question.poll_id,
            question_index: this.state.questionIndex
        }
        await wsPoll.updateQuestion(question);
        await wsPoll.updateOptions(question.id, this.state.options);
    }
    handleOptionDialogClose(){
        this.setState({newOptionDialogOpen: false});
    }
    handleNewOptionClick(){
        this.setState({newOptionDialogOpen: true});
    }
    handleDelete(oid){
        const {options}=this.state;
        let newOptions = []
        for (let index = 0; index < options.length; index++) {
            if (options[index].id !== oid){
                newOptions.push(options[index])
            }
        }
        this.setState({options: newOptions})
    }
    handleChange(e){
        this.setState({[e.target.name]: e.target.value})
    }
    renderOption(option) {
        const {classes}=this.props;
        if (option.image) {
            return (
                <div key={option.option_value} style={{marginBottom: "0.5em"}} className={classes.optionImage}>
                    <img src={option.image} alt="option image" width="80px"/>
                    <span className={classes.optionText}>{option.option_value}</span> 
                    {option.next_question_index !== -1 ? <Chip className={classes.button} size="small" variant="outlined" label={`Padre de pregunta ${option.next_question_index}`} color="primary" /> : <Chip className={classes.button} size="small" variant="outlined" label="Termina" color="secondary" />}
                    <IconButton aria-label="delete" className={classes.deleteIcon}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            )
        } else {
            return (
                <div key={option.option_value} style={{marginBottom: "0.5em"}}>
                    <Chip 
                        label={option.option_value}
                        onDelete={() => this.handleDelete(option.id)}
                    />
                    {option.id}
                    {option.next_question_index !== -1 ? <Chip className={classes.button} size="small" variant="outlined" label={`Padre de pregunta ${option.next_question_index}`} color="primary" /> : <Chip className={classes.button} size="small" variant="outlined" label="Termina" color="secondary" />}
                    <br />
                </div>
            )
        }
    }
    renderOptions(){
        const {options} = this.state;

        const optionsDiv = options.map((o) => {
            return this.renderOption(o);
        })
        return (
            <div>
                {optionsDiv}
                <br />
                <NewOptionLauncher handleClick={this.handleNewOptionClick} />
            </div>
        )
    }
    renderNewOptionField(){
        const {newOptionValue}=this.state;
        return (
            <TextField
                label="Nueva opción"
                value={newOptionValue}
                name="newOptionValue"
                onChange={this.handleChange}
            />
        )
    }
    renderQuestionAnswerFields(){
        const {answerType} = this.state;
        switch (answerType) {
            case "multiple_choice":
                return this.renderOptions();
            case "multiple_selection":
                return this.renderOptions();
            case "user_input":
                return <span />
            case "static":
                return <p>Ingresa el texto que se debe mostrar en este slide estático</p>
            default:
                break;
        }
    }
    createOption(option){
        this.setState({ options: [...this.state.options, option], newOptionDialogOpen: false} )
    }
    checkIfCanUploadImage(){
        const {options}=this.state;
        if (options.length == 0) return true;
        else {
            for (let index = 0; index < options.length; index++) {
                if (options[index].image) return true;
            }
        }
        return false;
    }
    render() {
        const {classes, question}=this.props;
        const {questionText, answerType, questionIndex} = this.state;
        return(
            <div>
            <div className={classes.root}>
                <Paper elevation={1} className={classes.paper}>
                    <div className={classes.mb}>
                        <TextField 
                            label="Índice de la pregunta"
                            value={questionIndex}
                            name="questionIndex"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={classes.mb}>
                        <TextField 
                            fullWidth
                            label="Texto de la pregunta"
                            value={questionText}
                            name="questionText"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={classes.mb}>
                        <Select
                            value={answerType}
                            onChange={this.handleChange}
                            name="answerType"
                        >
                            <MenuItem value="multiple_choice">Opción múltiple</MenuItem>
                            <MenuItem value="multiple_selection">Selección múltiple</MenuItem>
                            <MenuItem value="user_input">Input</MenuItem>
                            <MenuItem value="slide">Slide estático</MenuItem>
                        </Select>
                    </div>
                    <div className={`${classes.mb} ${classes.ml}`}>
                        {this.renderQuestionAnswerFields()}
                    </div>
                    <div className={`${classes.mb} ${classes.mt}`}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                            Eliminar
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            onClick={this.saveQuestion}
                        >
                        Guardar
                        </Button>
                    </div>
                </Paper>
            </div>
            <NewOptionDialog handleCreateOption={this.createOption} canUploadImage={this.checkIfCanUploadImage()} onClose={this.handleOptionDialogClose} open={this.state.newOptionDialogOpen} />
            </div>
        )
    }
}

export default withStyles(styles)(PollQuestion);