import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { UIDimentions,  } from "../../constants";
import MenuItem from './MenuItem';

const styles = theme => ({
   
    drawer: {
        width: UIDimentions.DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: UIDimentions.DRAWER_WIDTH * .75,
        
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        backgroundColor: 'white',
        ...theme.mixins.toolbar,
    }
});

const SideMenu = ({ classes, open, menuItems, match }) => {

    return (
        <Drawer
            variant="persistent"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <div className={classes.toolbar} />
            <List>
                {menuItems.map(data => {
                    return <MenuItem key={data.id} label={data.label} route={`/u${data.slug}`} selected={false}/> 
                })}
            </List>
        </Drawer>
    )

}

export default withStyles(styles)(SideMenu);