import {createStyles} from "@material-ui/core/index";
import {green} from '@material-ui/core/colors';

const list = createStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 'auto',
        overflow: 'hidden'
    },
    appBarSpacer: {
        minHeight: 15
    },
    tableContainer: {
        position: 'relative'
    },
    floatingLoader: {
        position: 'absolute',
        top: '60%',
        left: '50%',
        zIndex: 101
    },
    editIconRoot: {
        borderRadius: 5,
        padding: 0,
        backgroundColor: 'rgba(0,0,0, 0.08)',
        '& :hover': {
            borderRadius: 5,
            backgroundColor: 'rgba(30,144,254,0.5)',
            "& > svg": {
                color: "#FFF"
            }
        },
    },
    editIcon: {
        padding: 2,
        color: '#1E90FF',
    }
}));

const form = createStyles((theme) => ({
    formControl: {
        minWidth: 200,
        maxWidth: 300,
        margin: "0.5em 1em 0.5em 1em",
    },
    formControlLg: {
        margin: "0.5em 1em 0.5em 1em",
        width: "100%",
    },
    formControlMd: {
        margin: "0.5em 1em 0.5em 1em",
        width: 450,
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
    success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 14,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2,
        height: 25
    },
    container: {},
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 'auto',
        overflow: 'hidden'
    },
    appBarSpacer: {
        minHeight: 40
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    actionsContainer: {
        textAlign: 'right'
    },
    sectionHeader: {
        margin: theme.spacing(3, 2),
    },
    sectionContent: {
        margin: theme.spacing(3, 2, 3),
    },
    outlined: {
        backgroundColor: 'white',
        paddingLeft: 2,
        paddingRight: 2
    },
    selectRoot: {
        textAlign: 'left'
    },
    fileInput: {
        display: 'none'
    },
    fileName: {
        fontSize: '0.9rem'
    },
    multiHasContent: {
        '& > div': {
            height: 'auto !important',
            '& > div': {
                padding: '8.5px 14px !important'
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    iconButtonRoot: {
        MuiButtonBase: {
            root: {
                color: 'red',
                backgroundColor: 'rgba(0,0,0, 0.08) !important',
            }
        }
    },
    imagePreview: {
        padding: theme.spacing(1),
        '& img': {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            border: 'solid 1px',
            width: '50%'
        }
    },
    
}));



const wrapper = createStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    }
    
}));


export default {
    list,
    form,
    wrapper
};