import React, {Component, useContext} from 'react';
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';
import {Snackbar, SnackbarContent, withStyles} from '@material-ui/core';
import styles from './styles';

const CategoryContext = React.createContext({
    category: null
});

class CategoryWrapper extends Component {

    constructor(props) {
        super(props);
        // this.setCategory = (category) => this.setState({ category });
        this.state = {
            category: null,
            snackbar: {
                open: false,
                message: null
            }
            // setCategory: this.setCategory
        };
    }

    setCategory = (category) => this.setState({category});

    showSnackBar = (message) => this.setState((prevState) => ({...prevState, snackbar: {open: true, message}}));

    hideSnackBar = () => this.setState((prevState) => ({...prevState, snackbar: {open: false, message: null}}));

    render() {
        const {history, match, classes} = this.props;
        const {state: {category, snackbar}, setCategory, showSnackBar, hideSnackBar} = this;
        return (
            <CategoryContext.Provider value={{
                category,
                setCategory,
                showSnackBar,
                hideSnackBar
            }}>
                {
                    match.params.action === undefined
                        ? (<CategoryList/>)
                        : match.params.action !== undefined &&
                        (match.params.action === 'nuevo' || match.params.action === 'editar')
                        ? (<CategoryForm/>)
                        : history.goBack()
                }
                <Snackbar
                    onClose={() => hideSnackBar()}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snackbar.open}
                    autoHideDuration={3500}
                >
                    <SnackbarContent
                        className={classes.success}
                        message={snackbar.message}
                    />
                </Snackbar>
            </CategoryContext.Provider>
        )
    }
}

export const useCategory = () => useContext(CategoryContext);

export default withStyles(styles.wrapper)(CategoryWrapper);