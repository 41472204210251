export const API_URLS = {
  WALLETS: {
    GET_WALLETS: `/admin/wallets`,
  },
  ALLIES: `/admin/allies`,
  CATEGORIES: `/admin/categories`,
  MEMBERS: {
    GET_MEMBERS: `/admin/members`,
  },
  BENEVITS: `/admin/benevits`,
  POLLS: "/admin/polls",
  QUESTIONS: "/admin/questions",
  OPTIONS: "/admin/options",
  CLEAR_OPTIONS: "/admin/clear_options",
  CATEGORIES: "/admin/categories",
  TERRITORIES: "/admin/territories",
  TEMPORALITY: {
    GET_TEMPORALITY: "admin/site_themes",
    PUT_TEMPORALITY: "admin/site_themes",
  },
  UPLOAD_IMAGE_BENEVITS: "admin/upload-image"
};

export const BASE_DEV = process.env.URL_API_DEV;

export const BASE_QA = process.env.URL_API_QA;

export const BASE_PROD = process.env.URL_API_PROD;

export const LocalStorageConstants = {
  user: "user",
  token: "token",
};

export const UIDimentions = {
  DRAWER_WIDTH: 210,
};

export const Color = {
  DARK: "#303539",
  MAIN: "#E61737",
  WARN: "#E77470",
  FAV: "#DF3B36",
  UPLOAD_FAV: "#BB85D6",
  PURPLE: "#BB85D6",
  PENDING: "#E7D070",
  APPROVED: "#6CBA7B",
};

function padZero(str, len) {
  len = len || 2;
  let zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export const invertColor = (hex, bw) => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
};
