import React, {useState, useEffect} from 'react';
import styles from './styles';
import {
    withStyles,
    Grid,
    Tooltip,
    IconButton,
    Button,
    CircularProgress,
    Chip
} from "@material-ui/core";
import {
    Add as AddIcon,
    Edit as EditIcon
} from "@material-ui/icons";
import {useHistory} from 'react-router-dom';
import MuiDataTables from 'mui-datatables';
import wsCategories from '../../api/wsCategories';
import moment from "moment";
import 'moment/locale/es';
import {useCategory} from './CategoryWrapper';
import _ from "lodash";
import {invertColor} from "../../constants";



const CategoryList = ({context, classes}) => {
    const history = useHistory();
    const {setCategory} = useCategory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        wsCategories.getCategories().then((response) => {
          console.log("categories", response);
            setData(data.concat(response));
        }).finally(() => setLoading(false));
    }, []);
    const columns = [
      
      {
        label: "ID",
        name: "id",
        options: {
          display: "true",
        },
      },
      {
        label: "Nombre",
        name: "name",
        options: {
          display: "true",
        },
      },
      {
        label: "Icono",
        name: "icon_url",
        options: {
          display: "true",
          customBodyRender: (value) => {
            return <img src={value} width={60} alt="" />;
          },  
        },
      },
      {
        label: "Banner",
        name: "banner_url",
        options: {
          display: "true",
          customBodyRender: (value) => {
            return <img src={value} width={60} alt="" />;
          },
        },
      },
    
      {
        label: "Color",
        name: "color",
        options: {
          display: "true",
          customBodyRender: (value, tableMeta, updateValue) => {
            const hexRegex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
            let toRender = "";
            if (value && value.match(hexRegex)) {
              const color = invertColor(value, true);
              toRender = (
                <Chip
                  size={"small"}
                  label={value.toUpperCase()}
                  style={{
                    backgroundColor: value,
                    color,
                    border: "solid 1px black",
                  }}
                />
              );
            }
            return toRender;
          },
        },
      },

      
      {
        name: "Acciones",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div>
                <Grid
                  container
                  spacing={1}
                  justify={"center"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Tooltip title={"Editar categoria"}>
                      <IconButton
                        size={"small"}
                        classes={{
                          root: classes.editIconRoot,
                        }}
                        onClick={() => {
                          setCategory(data[dataIndex]);
                          history.push("/u/categorias/editar");
                        }}
                      >
                        <EditIcon
                          classes={{
                            root: classes.editIcon,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            );
          },
        },
      },
    ];
    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
            >
                <Grid
                    container
                    item
                    md={12}
                    direction={'row'}
                    justify={'flex-end'}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={() => history.push('/u/categorias/nuevo')}
                        >
                            Agregar Categoria
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={12}
                    classes={{
                        root: classes.tableCellContainer
                    }}
                >
                    {
                        loading && (
                            <CircularProgress
                                size={30}
                                classes={{
                                    root: classes.floatingLoader
                                }}
                            />
                        )
                    }
                    <MuiDataTables
                        style={{tableLayout: 'fixed'}}
                        columns={columns}
                        data={data}
                        options={{
                            enableNestedDataAccess: '.',
                            selectableRows: 'none',
                            rowsPerPage: 15,
                        }}
                    />
                </Grid>
            </Grid>
            <div className={classes.appBarSpacer}/>
        </React.Fragment>
    );
};

/*const withContext = (Component) => (props) => (
    <AllyContext.Consumer>
        {(context) => (<Component {...props} context={context}/>)}
    </AllyContext.Consumer>
);*/

export default withStyles(styles.list)(CategoryList);
// export default withContext(withStyles(styles.list)(AllyList));
