import axios from "axios";
import { API_URLS } from '../constants';

const getWallets = async () => {
    const host = localStorage.getItem('host') + '/api/v1/';
    try {
        const x = Math.floor(Math.random() * 500) + 1;
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
                "X-Trace":x
            }
        }
        const res = await axios.get(host + API_URLS.WALLETS.GET_WALLETS, headers);
        return res.data;
    } catch(error) {
        console.log(error);
    }
    
};

export default {
    getWallets
}