import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { UIDimentions, Color } from "../../constants";
import logo from "../../assets/images/logo.png";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import history from "../../history";
const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    color: Color.MAIN,
    backgroundColor: "white"
  },
  appBarShift: {
    marginLeft: UIDimentions.DRAWER_WIDTH,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 5
  },
  hide: {
    display: "none"
  }
});



const Header = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    history.push("/login");
    setAnchorEl(null);
  }
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const { classes } = props;
  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: props.open
      })}
    >
      <Toolbar disableGutters={false}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.toggleDrawer.bind(this)}
          className={classNames(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
        <img height="40px" src={logo} alt="logo" />
        host: {localStorage.getItem('host')}
        <div
          style={{
            
            position: "absolute",
            right: 20,
            padding: 5
          }}
        >
          <div>
            Administrador
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{fontSize:'1.5em'}} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
