import React from 'react';

import SideMenu from "../presentational/SideMenu";
import Header from "../presentational/Header"
import { UIDimentions, } from "../../constants";
class Wrapper extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open:true,
            menuItems: [
                //{id: "dashboard", label: "Dashboard", slug: "/dashboard"},
                {id: "aliados", label: "Aliados", slug: "/aliados"},
                //{id: "usuarios", label: "Usuarios", slug: "/usuarios"},
                {id: "benevits", label: "Benevits", slug: "/benevits"},
                {id: "temporality", label: "Temporalidades", slug: "/temporality"},
                {id: "encuestas", label: "Encuestas", slug: "/encuestas"},
                {id: "categorias", label: "Categorias", slug: "/categorias"},
                
            ]
        }
    }
    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    render(){
        return(
            <div>
                <Header
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    open={this.state.open}
                    user={this.props.user}
                    // sendToRoute={this.sendToRoute.bind(this)}
                />
                <SideMenu 
                    match={this.props.match}
                    open={this.state.open}
                    toggleDrawer={this.toggleDrawer.bind(this)}
                    menuItems={this.state.menuItems}
                />
                <main
              style={{
                position: "absolute",
                top: 64,
                left: this.state.open ? UIDimentions.DRAWER_WIDTH : 60,
                right: 60
              }}
            >
              <div style={{ flexGrow: 1, position: "relative" }}>
                {this.props.children}
              </div>
            </main>
            </div>
        )
    }
}
export default Wrapper;