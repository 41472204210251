import React, {useEffect, useState} from 'react';
import {
    Grid,
    TextField,
    Button,
    Divider,
    Paper,
    Typography,
    CircularProgress,
    withStyles,
    FormHelperText,
    Chip
} from '@material-ui/core';
import {
    Save as SaveIcon,
    SettingsBackupRestore as SettingsBackupRestoreIcon,
    Publish as PublishIcon,
    ArrowBack as ArrowBackIcon
} from "@material-ui/icons";
import wsCategories from '../../api/wsCategories';
import classNames from 'classnames';
import moment from 'moment';
import {useFormik} from 'formik';
import styles from './styles';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {DropzoneArea} from 'material-ui-dropzone';
import {useCategory} from './CategoryWrapper';
import {useHistory, useParams} from 'react-router-dom';
import * as yup from "yup";
import { SketchPicker } from 'react-color';

const fileNameFromUrl = (url) => url.split("/").pop();
const validationSchema = yup.object().shape({
    name: yup.mixed('El nombre es requerido').required('El nombre es requerido'),
});


const CategoryForm = ({classes}) => {
    let colorSelected = '';
    const fixedHeightPaper = classNames(classes.paper, classes.fixedHeight);
    const history = useHistory();
    const params = useParams();
    const {category, setCategory, showSnackBar} = useCategory();
    const [key, setKey] = useState(0);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const handleBlur = () => {
        setIsPickerOpen(false);
      };
    const [currentColor, setCurrentColor] = useState(category?.color ?? '#ffffff');
    const hanleOnChange = (color)=>{
        setCurrentColor(color.hex)
        setFieldValue('primary_color', color.hex);

    }
    
    const appStyle = {backgroundColor: currentColor,borderRadius: '50%', width: '30px',
    height: '30px',
    border: '1px solid',
    marginLeft: '10px',
    marginTop: '10px'}

    const gridStyle = {
        display:'flex'
    }
    
    const [hidden, setHidden] = useState(false)
    const pickerStyles = {
        default: {
            picker: {
                position: 'absolute',
                right: '120px',
                right: '100px',
                top: '190px'
                
            }
        }
    }

    const {
        values,
        dirty,
        handleChange,
        setFieldValue,
        isSubmitting,
        handleSubmit,
        handleReset,
        setValues,
        errors
    } = useFormik({
        initialValues: {
            name: category?.name ?? null,
            mini_icon: category?.icon_url ?? null,
            mini_banner: category?.banner_url ?? null,
            primary_color: category?.color ?? null,
            // color: category?.color ?? null,
            // color: currentColor
            
        },

        
        
        onSubmit: (values, {resetForm, setSubmitting}) => {
            values.color = currentColor;
            const {name, mini_icon, mini_banner, color} = values;
            const copyValues = {...values};
            switch (params?.action) {
                case 'nuevo':
                    //then create a POST request
                    wsCategories.postCategory(copyValues).then((response) => {
                        resetForm();
                        setKey(1);
                        showSnackBar('Categoria creada exitosamente.');
                        history.push('/u/categorias');
                    }).finally(() => {
                        setSubmitting(false);
                    });
                    break;
                case 'editar':
                    //Then create a PUT request
                    Object.assign(copyValues, {
                        id: category.id
                    });
                    if (mini_icon?.name === undefined) Object.assign(copyValues, {
                        mini_icon: null
                    });
                    if (mini_banner?.name === undefined) Object.assign(copyValues, {
                        mini_banner: null
                    });
                    wsCategories.updateCategory(copyValues).then((response) => {
                        setKey(1);
                        resetForm({
                            values: {
                                //@formatter:off
                                name: category?.name ?? null,
                                mini_icon: category?.icon_url ?? null,
                                mini_banner: category?.banner_url ?? null,
                                primary_color: category?.color ?? null,
                                //@formatter:on
                            }
                        });
                        showSnackBar('Categoria actualizado exitosamente.');
                        history.push('/u/categorias');
                    }).finally(() => {
                        setSubmitting(false);
                    });
                    break;
                default:
            }
        },
        validationSchema: validationSchema
    });
    useEffect(() => {
        if (category === null && params?.action === 'editar') history.push('/u/aliados')
    }, [category]);
    useEffect(() => {
        return () => {
            if (params?.action === 'editar') setCategory(null)
        }
    }, []);
    
    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Paper className={fixedHeightPaper}
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    classes={{
                        root: classes.container
                    }}
                    spacing={2}
                >
                    <Grid
                        container
                        item
                        md={12}
                        direction="row"
                        spacing={5}
                    >
                        <Grid
                            item
                            md={6}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                {params?.action === 'editar' ? 'Actualizar' : 'Nueva'} Categoria
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            container
                            direction={'row'}
                            spacing={2}
                            justify={'flex-end'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                md={3}
                            >
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    color={'default'}
                                    size={'small'}
                                    startIcon={<ArrowBackIcon/>}
                                    margin={'dense'}
                                    onClick={() => history.push('/u/categorias')}
                                >
                                    Volver
                                </Button>
                            </Grid>
                            
                            <Grid
                                item
                                md={3}
                            >
                                
                                <div className={classes.wrapper}>
                                    <Button
                                        disabled={params?.action === 'editar' && !dirty}
                                        fullWidth
                                        variant={'contained'}
                                        color={'secondary'}
                                        size={'small'}
                                        startIcon={<SaveIcon/>}
                                        margin={'dense'}
                                        onClick={handleSubmit}
                                    >
                                        {params?.action === 'editar' ? 'Actualizar' : 'Guardar'}
                                    </Button>
                                    {
                                        isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={12}
                    >
                        <Divider/>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            md={8}
                        >
                            <TextField
                                error={errors?.name !== undefined}
                                helperText={errors?.name}
                                fullWidth
                                label={'Nombre'}
                                value={values.name ?? ''}
                                margin={'dense'}
                                name={'name'}
                                onChange={handleChange}
                                variant={'outlined'}
                            />
                        </Grid>

                        <Grid item md={4} sm={12} style={gridStyle}>

                        <TextField
                        error={errors?.color !== undefined}
                        helperText={errors?.color}
                        margin={"dense"}
                        onChange={handleChange}
                        name={"primary_color"}
                        value={currentColor}
                        label="Color"
                        variant="outlined"
                        type={"text"}
                        // onClick={() => setHidden(!hidden)}
                        onClick={() => setIsPickerOpen(true)}
                        />
                         <div
                        style={appStyle}
                        >
                        </div>                     
                        </Grid>

                    <Grid
                            item
                            md={4}
                        >
                            {isPickerOpen && (
                            <div> 
                                
                                 <div>
                                 <SketchPicker
                                type="sketch"
                                styles={pickerStyles}
                                color={currentColor}
                                onChangeComplete={hanleOnChange}
                                />
                                 </div>

                                <button 
                                style={{
                                    position: 'absolute',
                                    right: '102px',
                                    top: '462px',
                                  }}
                                  onClick={() => setIsPickerOpen(false)}>Ok</button>
                            </div>
                            )}
                    </Grid>


                    </Grid>
                    <Grid
                        item
                        container
                        md={12}
                        direction={'row'}
                        justify={'flex-start'}
                        alignItems={'flex-end'}
                        spacing={4}
                    >

                        <Grid
                            item
                            md={4}
                        >
                            {
                                values.mini_icon && (
                                    <div className={classes.imagePreview}>
                                        <img
                                            src={values.mini_icon?.url ?? values?.mini_icon}
                                            alt=""
                                        />
                                    </div>
                                )
                            }
                            <Button
                                fullWidth
                                component={'label'}
                                variant={'contained'}
                                color={'primary'}
                                size={'medium'}
                                startIcon={<PublishIcon/>}
                            >
                                Icono de la categoria
                                <input
                                    type={'file'}
                                    name={'mini_icon'}
                                    style={{display: "none"}}
                                    onChange={(event) => {
                                        const {files} = event.target;
                                        const createUrl = URL.createObjectURL(files[0]);
                                        Object.assign(files[0], {url: createUrl});
                                        setFieldValue('mini_icon', files[0]);
                                    }}
                                />
                            </Button>
                            {
                                errors?.mini_icon !== undefined && (
                                    <FormHelperText error>
                                        {errors?.mini_icon}
                                    </FormHelperText>
                                )
                            }
                        </Grid>

                        <Grid
                            item
                            md={4}
                        >
                            {
                                values.mini_banner && (
                                    <div className={classes.imagePreview}>
                                        <img
                                            //@formatter:off
                                            src={values.mini_banner?.url ?? values?.mini_banner}
                                            //@formatter:on
                                            alt=""
                                        />
                                    </div>
                                )
                            }
                            <Button
                                fullWidth
                                component={'label'}
                                variant={'contained'}
                                color={'primary'}
                                size={'medium'}
                                startIcon={<PublishIcon/>}
                            >
                                Banner de la categoria
                                <input
                                    type={'file'}
                                    name={'mini_banner'}
                                    style={{display: "none"}}
                                    onChange={(event) => {
                                        const {files} = event.target;
                                        const createUrl = URL.createObjectURL(files[0]);
                                        Object.assign(files[0], {url: createUrl});
                                        setFieldValue('mini_banner', files[0]);
                                    }}
                                />
                            </Button>
                            {
                                errors?.mini_banner !== undefined && (
                                    <FormHelperText error>
                                        {errors?.mini_banner}
                                    </FormHelperText>
                                )
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>
            <div className={classes.appBarSpacer}/>
        </React.Fragment>
    );
};

export default withStyles(styles.form)(CategoryForm);
