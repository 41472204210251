import axios from 'axios';
import {API_URLS} from '../constants';

const getTemporality = async () => {
    const host = `${localStorage.getItem('host')}/api/v1/`;
    let response = null;
    try {
        const x = Math.floor(Math.random() * 500) + 1;
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case": (Math.round((new Date()).getTime() / 1000)) * x,
                "X-Trace": x
            }
        };
        response = (await axios.get(`${host}${API_URLS.TEMPORALITY.GET_TEMPORALITY}`, headers))?.data;
    } catch (e) {
        console.log(e);
    }
    return response;
};

const putTemporality = async (has_active_theme, desktop_theme, mobile_theme) => {
    const host = `${localStorage.getItem('host')}/api/v1/`;
    let response = null;
    try {
        const x = Math.floor(Math.random() * 500) + 1;
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case": (Math.round((new Date()).getTime() / 1000)) * x,
                "X-Trace": x
            }
        };

        const formData = new FormData();
        formData.append('has_active_theme', has_active_theme);
        if (desktop_theme !== null) formData.append('desktop_theme', desktop_theme);
        if (mobile_theme !== null) formData.append('mobile_theme', mobile_theme);

        response = (await axios.put(`${host}${API_URLS.TEMPORALITY.GET_TEMPORALITY}`, formData, headers))?.data;
    } catch (e) {
        console.log(e);
    }
    return response;
};

export default {
    getTemporality,
    putTemporality
}