import React, {useState, useEffect} from 'react';
import styles from './styles';
import {
    withStyles,
    Grid,
    Tooltip,
    IconButton,
    Button,
    CircularProgress
} from "@material-ui/core";
import {
    Add as AddIcon,
    Edit as EditIcon
} from "@material-ui/icons";
import {useHistory} from 'react-router-dom';
import MuiDataTables from 'mui-datatables';
import wsAlly from '../../api/wsAlly';
import moment from "moment";
import 'moment/locale/es';
import {useAlly} from './AllyWrapper';
import _ from "lodash";

const AllyList = ({context, classes}) => {
    const history = useHistory();
    const {setAlly} = useAlly();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        wsAlly.getAllies().then((response) => {
            setData(data.concat(response));
        }).finally(() => setLoading(false));
    }, []);
    const columns = [
      {
        label: "ID",
        name: "id",
        options: {
          display: "true",
        },
      },
      {
        label: "Imagen",
        name: "mini_logo_full_path",
        options: {
          display: "true",
          customBodyRender: (value) => {
            return <img src={value} width={60} alt="" />;
          },
        },
      },
      {
        label: "Nombre",
        name: "name",
        options: {
          display: "true",
        },
      },
      {
        label: "Benevits",
        name: "benevits",
        options: {
          display: "true",
          customBodyRender: (value) => {
            let arrayName = value.map((el) => el.name);
            let arrayToString = "";
            let last = "";
            if (arrayName.length > 0) {
              arrayToString = arrayName.join(", ");
              if (arrayName.length > 1) {
                const has3More = arrayName.length > 3;
                if (has3More) {
                  const slice = _.slice(arrayName, 3, arrayName.length);
                  arrayName = _.slice(arrayName, 0, 3);
                  last = `${slice.length} más`;
                } else {
                  last = arrayName.pop();
                }
                arrayToString = `${arrayName.join(", ")} y ${last}`;
              }
            }
            return arrayToString;
          },
        },
      },
      {
        label: "Contacto",
        name: "representative_name",
        options: {
          display: "true",
        },
      },
      {
        label: "Fecha de inicio",
        name: "start_date",
        options: {
          display: "true",
          customBodyRender: (value) => {
            let dateFormated = "";
            let styles = {};
            if (moment(value).isValid()) {
              const date = moment(value).utc();
              date.locale("es-mx");
              dateFormated = date.format("LL");
            }

            return <span style={styles}>{dateFormated}</span>;
          },
        },
      },
      {
        label: "Fecha de renovación",
        name: "renovation_date",
        options: {
          display: "true",
          customBodyRender: (value) => {
            let dateFormated = "";
            let styles = {};
            if (moment(value).isValid()) {
              const date = moment(value).utc();
              date.locale("es-mx");
              dateFormated = date.format("LL");
            }

            return <span style={styles}>{dateFormated}</span>;
          },
        },
      },
      {
        name: "Acciones",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div>
                <Grid
                  container
                  spacing={1}
                  justify={"center"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Tooltip title={"Editar Aliado"}>
                      <IconButton
                        size={"small"}
                        classes={{
                          root: classes.editIconRoot,
                        }}
                        onClick={() => {
                          setAlly(data[dataIndex]);
                          history.push("/u/aliados/editar");
                        }}
                      >
                        <EditIcon
                          classes={{
                            root: classes.editIcon,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            );
          },
        },
      },
    ];
    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
            >
                <Grid
                    container
                    item
                    md={12}
                    direction={'row'}
                    justify={'flex-end'}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={() => history.push('/u/aliados/nuevo')}
                        >
                            Agregar Aliado
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={12}
                    classes={{
                        root: classes.tableCellContainer
                    }}
                >
                    {
                        loading && (
                            <CircularProgress
                                size={30}
                                classes={{
                                    root: classes.floatingLoader
                                }}
                            />
                        )
                    }
                    <MuiDataTables
                        style={{tableLayout: 'fixed'}}
                        columns={columns}
                        data={data}
                        options={{
                            enableNestedDataAccess: '.',
                            selectableRows: 'none',
                            rowsPerPage: 15,
                        }}
                    />
                </Grid>
            </Grid>
            <div className={classes.appBarSpacer}/>
        </React.Fragment>
    );
};

/*const withContext = (Component) => (props) => (
    <AllyContext.Consumer>
        {(context) => (<Component {...props} context={context}/>)}
    </AllyContext.Consumer>
);*/

export default withStyles(styles.list)(AllyList);
// export default withContext(withStyles(styles.list)(AllyList));
