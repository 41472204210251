import axios from 'axios';
//import { API_URLS } from '../constants';

const login = async (host, email, password) => {
    try {
        const x = Math.floor(Math.random() * 500) + 1;
        const res = await axios.post(`${host}/api/v1/login`, {
                user: {
                    email: email,
                    password: password
                }
            },
            {
                headers:{
                    "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
                    "X-Trace":x,
                }
            }
        );
        return {
            user: res.data,
            token: res.headers.authorization,
            errors: null
        };
    } catch (error) {
        return {
            user: null,
            token: null,
            errors: error.response
        }
    }

};

export default {
    login
}