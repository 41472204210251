import axios from "axios";
import { API_URLS } from '../constants';

const getAllies = async () => {
    const host = localStorage.getItem('host') + '/api/v1/';
    const x = Math.floor(Math.random() * 500) + 1;
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
                "X-Trace":x,
            }
        }
        const res = await axios.get(host + API_URLS.ALLIES, headers);
        return res.data;
    } catch(error) {
        console.log(error);
    }

};

const postAlly = async (ally) => {
    try {
        const host = localStorage.getItem('host') + '/api/v1';

    const headers = {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        }
    }
    let formData = new FormData();
    formData.set('ally[name]', ally.name)
    formData.set('ally[representative_name]', ally.representative_name)
    formData.set('ally[representative_telephone_number]', ally.representative_telephone_number)
    formData.set('ally[telephone_number]', ally.telephone_number)
    formData.set('ally[email]', ally.email)
    formData.set('ally[address]', ally.address)
    formData.set('ally[start_date]', ally.start_date)
    formData.set('ally[renovation_date]', ally.renovation_date)
    formData.set('ally[description]', ally.description)
    if (ally.mini_logo) {
        formData.set('ally[mini_logo]', ally.mini_logo)
    }
    if (ally.documents){
        for (let index = 0; index < ally.documents.length; index++) {
            formData.append('ally[documents][]', ally.documents[index])
        }
    }

        return new Promise((resolve, reject) => (
            axios
                .post(host + API_URLS.ALLIES, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ));
    } catch (error) {
        return {error: error}
    }

};
const updateAlly = async (ally) => {
    try {
        const host = localStorage.getItem('host') + '/api/v1';

    const headers = {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'multipart/form-data',
        }
    }
    let formData = new FormData();
    formData.set('ally[name]', ally.name)
    formData.set('ally[representative_name]', ally.representative_name)
    formData.set('ally[representative_telephone_number]', ally.representative_telephone_number)
    formData.set('ally[telephone_number]', ally.telephone_number)
    formData.set('ally[email]', ally.email)
    formData.set('ally[address]', ally.address)
    formData.set('ally[start_date]', ally.start_date)
    formData.set('ally[renovation_date]', ally.renovation_date)
    formData.set('ally[description]', ally.description)
    if (ally.mini_logo) {
        formData.set('ally[mini_logo]', ally.mini_logo)
    }
    if (ally.documents){
        for (let index = 0; index < ally.documents.length; index++) {
            formData.append('ally[documents][]', ally.documents[index])
        }
    }

        return new Promise((resolve, reject) => (
            axios
                .put(host + API_URLS.ALLIES + '/' + ally.id, formData, headers)
                .then((response) => response.data)
                .then(resolve)
                .catch((error) => error)
                .then(reject)
        ));
    } catch (error) {
        return {error: error}
    }

};
export default {
    getAllies,
    postAlly,
    updateAlly
}