import React from "react";
import {withStyles}from "@material-ui/core/styles";
import Poll from "./Poll";
import PollsTable from "./PollsTable";
import wsPoll from "../../api/wsPoll";
import {
    Grid
} from "@material-ui/core";
const styles = {
    root: {
        display: "flex"
    }
}
class PollsView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            polls: [],
            selectedPoll: null
        }
        this.handlePollSelected = this.handlePollSelected.bind(this);
    }
    async componentDidMount(){
        const resp = await wsPoll.getPolls();
        if (resp){
            this.setState({polls: resp, selectedPoll: resp[0]});
        }
    }
    handlePollSelected(selectedPoll){
        this.setState({selectedPoll})
    }
    render() {
        return(
            <Grid container justify="center" alignItems="flex-start" spacing={3}>
                <Grid item xs={12} sm={4}>
                    <PollsTable polls={this.state.polls} pollSelected={this.handlePollSelected} />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Poll poll={this.state.selectedPoll} />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(PollsView);