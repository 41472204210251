import axios from "axios";
import { API_URLS } from '../constants';

function headers(){
    const x = Math.floor(Math.random() * 500) + 1;
    return {
        headers: {
            Authorization: localStorage.getItem("token"),
            "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
            "X-Trace":x
        }
    }
}

const getPolls = async () => {
    const host = localStorage.getItem('host') + '/api/v1/';
    console.log(host)
    const x = Math.floor(Math.random() * 500) + 1;
    try {
        const headers = {
            headers: {
                Authorization: localStorage.getItem("token"),
                "X-Case":(Math.round((new Date()).getTime() / 1000))*x,
                "X-Trace":x
            }
        }
        const res = await axios.get(host + API_URLS.POLLS, headers);
        return res.data;
    } catch(error) {
        console.log(error);
    }
    
};

const updateQuestion = async (question) => {
    const host = localStorage.getItem('host') + '/api/v1/';
    console.log(host)
    const x = Math.floor(Math.random() * 500) + 1;
    try {
        const data = {
            question
        }
        const res = await axios.put(host + API_URLS.QUESTIONS + '/' + question.id, data, headers());
        return res.data;
    } catch(error) {
        console.log(error);
    }
};

const updateOptions = async (question_id, options) => {
    const host = localStorage.getItem('host') + '/api/v1/';
    console.log(host)
    const x = Math.floor(Math.random() * 500) + 1;
    try {
        await axios.delete(host + API_URLS.CLEAR_OPTIONS + '/' + question_id, headers());
        for (let index = 0; index < options.length; index++) {
            options[index].question_id = question_id
            const data = {
                option: options[index]
            }
            axios.post(host + API_URLS.OPTIONS, data, headers());
        }
        
        
    } catch(error) {
        console.log(error);
    }
};

export default {
    getPolls,
    updateQuestion,
    updateOptions
}