import React, {useEffect, useState} from 'react';
import {
    withStyles,
    Grid,
    Tooltip,
    CircularProgress,
    Button,
    Chip,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import styles from './styles';
import MUIDataTable from 'mui-datatables';
import services from "../../api/wsBenevit";
import moment from "moment";
import 'moment/locale/es';
import {invertColor} from "../../constants";
import _ from 'lodash';
import {useBenevit} from './BenevitWrapper';

const useEditStyles = makeStyles(() => ({
    root: {
        borderRadius: 5,
        padding: 0,
        backgroundColor: 'rgba(0,0,0, 0.08)',
        '& :hover': {
            borderRadius: 5,
            backgroundColor: 'rgba(30,144,254,0.5)',
            "& > svg": {
                color: "#FFF"
            }
        },
    },
    icon: {
        padding: 5,
        color: '#1E90FF',
    }
}));
const useRemoveStyles = makeStyles(() => ({
    root: {
        borderRadius: 5,
        padding: 0,
        backgroundColor: 'rgba(0,0,0, 0.08)',
        '& :hover': {
            borderRadius: 5,
            backgroundColor: 'rgba(245,0,87,0.5)',
            "& > svg": {
                color: "#FFF"
            }
        },
    },
    icon: {
        padding: 5,
        color: '#f50057',
    }
}));


const BenevitList = ({classes}) => {
    const styleEdit = useEditStyles();
    const {setBenevit} = useBenevit();
    const styleRemove = useRemoveStyles();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const fethBenevits = async () => {
            const data = await services.getBenevits();
            setData(data)
            setLoading(false);
        };
        setLoading(true);
        fethBenevits();
    }, []);
    const columns = [
      {
        label: "ID",
        name: "id",
        options: {
          display: "true",
        },
      },
      {
        label: "Nombre",
        name: "name",

        options: {
          display: "true",
        },
      },
      {
        label: "Descripción",
        name: "description",
        options: {
          display: "false",
        },
      },
      {
        label: "Titulo",
        name: "title",
        options: {
          display: "true",
        },
      },
      {
        label: "Instrucciones",
        name: "instructions",
        options: {
          display: "false",
        },
      },
      {
        label: "Fecha de Expiración",
        name: "expiration_date",
        options: {
          display: "true",
          customBodyRender: (value, tableMeta, updateValue) => {
            let dateFormated = "";
            let styles = {};
            if (moment(value).isValid()) {
              const date = moment(value).utc();
              const now = moment().utc();
              const diff = date.diff(now, "days");
              if (diff < 0) styles = { color: "red", fontWeight: "bold" };
              date.locale("es-mx");
              dateFormated = date.format("LL");
            }

            return <span style={styles}>{dateFormated}</span>;
          },
        },
      },
      {
        label: "Activo",
        name: "active",
        options: {
          display: "false",
        },
      },
      {
        label: "Color",
        name: "primary_color",
        options: {
          display: "true",
          customBodyRender: (value, tableMeta, updateValue) => {
            const hexRegex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
            let toRender = "";
            if (value && value.match(hexRegex)) {
              const color = invertColor(value, true);
              toRender = (
                <Chip
                  size={"small"}
                  label={value.toUpperCase()}
                  style={{
                    backgroundColor: value,
                    color,
                    border: "solid 1px black",
                  }}
                />
              );
            }
            return toRender;
          },
        },
      },
      {
        label: "Slug",
        name: "slug",
        options: {
          display: "false",
        },
      },
      {
        label: "Aliado",
        name: "ally.name",
        options: {
          display: "true",
        },
      },
      {
        label: "Cartera",
        name: "wallet.name",
        options: {
          display: "true",
        },
      },
      {
        label: "Disponible en",
        name: "territories",
        options: {
          display: "true",
          customBodyRender: (value, tableMeta, updateValue) => {
            let arrayName = value.map((el) => el.name);
            let arrayToString = "";
            let last = "";
            if (arrayName.length > 0) {
              arrayToString = arrayName.join(", ");
              if (arrayName.length > 1) {
                const has3More = arrayName.length > 3;
                if (has3More) {
                  const slice = _.slice(arrayName, 3, arrayName.length);
                  arrayName = _.slice(arrayName, 0, 3);
                  last = `${slice.length} más`;
                } else {
                  last = arrayName.pop();
                }
                arrayToString = `${arrayName.join(", ")} y ${last}`;
              }
            }
            return arrayToString;
          },
        },
      },
      {
        name: "Acciones",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={{ width: 100 }}>
                <Grid
                  container
                  spacing={1}
                  justify={"center"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Tooltip title={"Editar Benevit"}>
                      <IconButton
                        size={"small"}
                        className={"test"}
                        classes={{
                          root: styleEdit.root,
                        }}
                        onClick={() => {
                          setBenevit(data[dataIndex]);
                          history.push("/u/benevits/editar");
                        }}
                      >
                        <EditIcon
                          classes={{
                            root: styleEdit.icon,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={"Eliminar Benevit"}>
                      <IconButton
                        size={"small"}
                        classes={{
                          root: styleRemove.root,
                        }}
                      >
                        <DeleteIcon
                          classes={{
                            root: styleRemove.icon,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            );
          },
        },
      },
    ];
    return (
        <React.Fragment>
            <div className={classes.appBarSpacer}/>
            <Grid
                container
                justify={'center'}
                alignItems={'center'}
                direction={'row'}
                spacing={2}
            >
                <Grid container item md={12} direction={'row'} justify={'flex-end'}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<AddIcon/>}
                            onClick={() => history.push('/u/benevits/nuevo')}
                        >
                            Agregar Benevit
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={12} classes={{
                    root: classes.tableCellContainer
                }}>
                    {
                        loading && (
                            <CircularProgress
                                size={30}
                                classes={{
                                    root: classes.floatingLoader
                                }}
                            />
                        )
                    }
                    <MUIDataTable
                        columns={columns}
                        data={data}
                        options={{
                            enableNestedDataAccess: '.',
                            selectableRows: 'none',
                            rowsPerPage: 15,
                            onTableChange: (action, tableState) => {
                                const indexCol = _.findIndex(tableState.columns, {name: 'active'});
                                _.forEach(tableState.data, ({index, data}) => {
                                    if (!data[indexCol]) {
                                        const row = document.getElementById(`MUIDataTableBodyRow-${index}`);
                                        if (row !== null) {
                                            row.style.background = '#ef9a9a';
                                        }
                                    }
                                })
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <div className={classes.appBarSpacer}/>
        </React.Fragment>
    );
};

export default withStyles(styles.list)(BenevitList);
