import React from "react";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from "@material-ui/core/styles";
import wsMember from "../../api/wsMember";


const styles = {
    root: {
      width: '100%',
    },
    tableWrapper: {
      maxHeight: "700px",
      overflow: 'auto',
    },
  }
  const columns = [
    { id: 'lastname', label: 'Apellido'},
    { id: 'name', label: 'Nombre'},
    { id: 'email', label: 'Email'},
    { id: 'mobile', label: 'Celular'},
    { id: 'id_socio_infonavit', label: 'ID Socio Infonavit'},
    { id: 'created_at', label: 'Fecha creación'},
    { id: 'updated_at', label: 'Fecha actualización'},
  ];


class Members extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            loading: true,
            page: 0,
            rowsPerPage: 15
        }
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }
    async componentDidMount() {
        const members = await wsMember.getMembers();
        setTimeout(() => {
            this.setState({ loading: false, members: members })    
        }, 1000);

    }
    handleChangePage(event, newPage){
        this.setState({page: newPage})
    }
    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: event.target.value
        })
    };
    renderTable() {
        const {classes} = this.props;
        const {page, rowsPerPage} = this.state;
        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell key="lastname">
                                            {row.lastname}
                                        </TableCell>
                                        <TableCell key="name">
                                            {row.name}
                                        </TableCell>
                                        <TableCell key="email">
                                            {row.user.email}
                                        </TableCell>
                                        <TableCell key="mobile">
                                            {row.mobile}
                                        </TableCell>
                                        <TableCell key="id_socio">
                                            {row.id_socio_infonavit}
                                        </TableCell>
                                        <TableCell key="created_at">
                                            {row.created_at}
                                        </TableCell>
                                        <TableCell key="updated_at">
                                            {row.updated_at}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 100]}
                    component="div"
                    count={this.state.members.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        )
    }
    render() {
        return (
            <div style={{paddingTop:"2em"}}>
                {this.renderTable()}
            </div>
        )
    }
}

export default withStyles(styles)(Members);